import React from "react";

export default function PL({ data, setUpdatedData }) {
  return (
    <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
      <div className="row">
        <div className="col-4">
          <h3>Profit &amp; Loss Statement</h3>
        </div>
        <div className="col-1">
          <h3>2022</h3>
        </div>
        <div className="col-2 px-5">
          <h3>2023</h3>
        </div>
      </div>
      {PLColumns.map((item, index) => (
        <div key={index} className="row my-2">
          <div className="col-4">
            <label style={item.bold ? { fontWeight: 600 } : {}}>
              {item.value || item}
            </label>
          </div>
          <div className="col-2 d-flex gap-3">
            {data?.extra_data?.form2?.[index]?.map((value, i) => (
              <input
                key={i}
                type="text"
                className="form-control form-control-sm input-width "
                placeholder="0"
                value={value}
               
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

const PLColumns = [
  "Income from sales",
  "Cost of sales",
  { value: "Gross profit (loss)", bold: true },
  "Other operating income",
  "Commercial expenses",
  "Administrative expenses",
  "including Amortization + Depreciation of fixed assets",
  "Other operating expenses",
  "including interest of customers and borrow my-2ers",
  { value: "Results of operational activity: profit (loss)", bold: true },
  "Income from sales",
  "Cost of sales",
  { value: "Gross profit (loss)", bold: true },
  "Other operating income",
  "Commercial expenses",
  "Administrative expenses",
];

