import {
  AnimalBudget,
  Client,
  Companies,
  CostOfServicesSummary,
  Costs,
  CropBudget,
  EquipmentNeeds,
  EquipmentNeedsPage,
  FuelCosts,
  Home,
  LaborCosts,
  Login,
  OperationsSummary,
  PasswordRecovery,
  QuestionnairePage,
  SalesSettings,
  SeedsSettings,
} from "pages";
import { Route, Routes } from "react-router-dom";

export default function AppRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Routes>
  );
}

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
    isPublic: true,
  },
  {
    path: "/costs",
    element: <Costs />,
  },
  {
    path: "/client/:id",
    link: "/client/32",
    element: <Client />,
  },
  {
    path: "/animal-budget",
    element: <AnimalBudget />,
  },
  {
    path: "/crop-budget",
    element: <CropBudget />,
  },
  {
    path: "/equipment-needs",
    element: <EquipmentNeeds />,
  },
  {
    path: "/password-recovery",
    element: <PasswordRecovery />,
    isPublic: true,
  },
  {
    path: "/fuel-costs",
    element: <FuelCosts />
  },
  {
    path: "/labor-costs",
    element: <LaborCosts />
  },
  {
    path: "/questionnaire/:id",
    link: "/questionnaire/323",
    element: <QuestionnairePage />
  },
  {
    path: "/cash-flow/:id",
    element: <EquipmentNeedsPage />
  },
  {
    path: "/sales-settings",
    element: <SalesSettings />
  },
  {
    path: "/seeds-settings",
    element: <SeedsSettings />
  },
  {
    path: "/cost-of-services-summary",
    element: <CostOfServicesSummary />
  },
  {
    path: "/operations-summary",
    element: <OperationsSummary />
  },
  {
    path: "/companies",
    element: <Companies />
  }
];
