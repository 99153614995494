import React from "react";

export default function Footer() {
  return (
    <div id="kt_app_footer" className="app-footer">
      <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">2023©</span>
          <a
            href="https://agrifinance.info"
            target="_blank"
            rel="noreferrer"
            className="text-gray-800 text-hover-primary"
          >
            IFC ECA Agrifinance
          </a>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <a
              href="https://agrifinance.info"
              target="_blank"
              rel="noreferrer"
              className="menu-link px-2"
            >
              About
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://agrifinance.info"
              target="_blank"
              rel="noreferrer"
              className="menu-link px-2"
            >
              Support
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
