import React from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Sheep({ data, setUpdatedData, selects }) {
  const { register, control } = useForm({
    defaultValues: {
      ewe: data?.sheep?.ewe,
      lamb: data?.sheep?.lamb,
    },
  });
  const {
    fields: ewes,
    append: appendEwe,
    remove: removeEwe,
  } = useFieldArray({
    name: "ewe",
    control,
  });
  const {
    fields: lambs,
    append: appendLamb,
    remove: removeLamb,
  } = useFieldArray({
    name: "lamb",
    control,
  });

  return (
    <div className="tab-pane fade" id="kt_tab_pane_c" role="tabpanel">
      <div className="row">
        <div className="col-6">
          <div className="p-3">
            <h3>Herd as of starting date</h3>
          </div>
          <div className="p-3">
            <h4>Ewes</h4>
          </div>
          <table>
            <thead>
              <tr className="text-end">
                <th>Quantity</th>
                <th>Weight, kg</th>
              </tr>
            </thead>
            <tbody>
              {ewes?.map((field, idx) => (
                <tr key={field.id} className="text-end">
                  <td>
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width input-width"
                      {...register(`ewe.${idx}.count`)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select form-select-sm "
                      aria-label="Default select example"
                      {...register(`ewe.${idx}.kg`)}
                    >
                      {selects.buyWeights?.[1]?.[3]?.map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="p-0 border-0 bg-transparent"
                      onClick={() => removeEwe(idx)}
                      id="rmBulls"
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3">
            <button
              onClick={appendEwe}
              className="btn btn-success mt-3 d-block btn-sm btn-sm"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
          <div className="mt-10 p-3">
            <h4>Lambs</h4>
          </div>
          <table>
            <thead>
              <tr className="text-end">
                <th>Quantity</th>
                <th>Weight, kg</th>
              </tr>
            </thead>
            <tbody>
              {lambs?.map((field, idx) => (
                <tr key={field.id} className="text-end">
                  <td>
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width input-width"
                      {...register(`lamb.${idx}.count`)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select form-select-sm "
                      aria-label="Default select example"
                      {...register(`lamb.${idx}.kg`)}
                    >
                      {selects.buyWeights?.[1]?.[1]?.map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="p-0 border-0 bg-transparent"
                      onClick={() => removeLamb(idx)}
                      id="rmBulls"
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3">
            <button
              onClick={appendLamb}
              className="btn btn-success mt-3 d-block btn-sm"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
        </div>
        <div className="col-6">
          <h3>Sale settings</h3>
          <b>Lambs</b>
          <div className="row align-items-center mt-3">
            <label htmlFor="">Sell when age reaches</label>
            <div className="col-3 mt-3">
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                value={data?.sheep?.feedperiod?.val}
                onChange={(e) =>
                  setUpdatedData((prev) => ({
                    ...prev,
                    sheep: {
                      ...(prev?.sheep || {}),
                      feedperiod: {
                        ...(prev?.sheep?.feedperiod || {}),
                        val: +e.target.value,
                      },
                    },
                  }))
                }
              >
                {selects?.ages?.[1]?.[1]?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3 d-flex gap-4">
              <label htmlFor="">months</label>
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="flexCheckDefault"
                  aria-label="Checkbox for following text input"
                  checked={data?.sheep?.feedperiod?.inuse}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      sheep: {
                        ...(prev?.sheep || {}),
                        feedperiod: {
                          ...(prev?.sheep?.feedperiod || {}),
                          inuse: +e.target.checked,
                        },
                      },
                    }))
                  }
                />
                In use
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="row mt-10 align-items-center">
              <label htmlFor="">Sell when weight reaches</label>
              <div className="col-3 mt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.sheep?.sellweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      sheep: {
                        ...(prev?.sheep || {}),
                        sellweight: {
                          ...(prev?.sheep?.sellweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.sellWeights?.[1]?.[1]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.sheep?.sellweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        sheep: {
                          ...(prev?.sheep || {}),
                          sellweight: {
                            ...(prev?.sheep?.sellweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row align-items-center mt-10">
              <div className="col-3">
                <span>Living animal sale</span> <br /> <br />
                <span>Slaughter and sale</span>
              </div>
              <div className="col-3">
                <label className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="sheep-resell-inuse"
                    id="sheep-resell-inuse"
                    checked={data?.sheep?.resell?.inuse}
                    value={1}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        sheep: {
                          ...(prev?.sheep || {}),
                          resell: {
                            ...(prev?.sheep?.resell || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>{" "}
                <br />
                <label className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="sheep-resell-inuse"
                    id="sheep-resell-inuse2"
                    checked={!data?.sheep?.resell?.inuse}
                    value={0}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        sheep: {
                          ...(prev?.sheep || {}),
                          resell: {
                            ...(prev?.sheep?.resell || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="my-5">
              <h3>Purchase settings</h3>
              <b>Lambs</b>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <label>Age</label>
              <div className="col-3 mt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.sheep?.buyage?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      sheep: {
                        ...(prev?.sheep || {}),
                        buyage: {
                          ...(prev?.sheep?.buyage || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.ages?.[1]?.[1]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">months</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.sheep?.buyage?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        sheep: {
                          ...(prev?.sheep || {}),
                          buyage: {
                            ...(prev?.sheep?.buyage || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <label>Weight</label>
              <div className="col-3 mt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.sheep?.buyweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      sheep: {
                        ...(prev?.sheep || {}),
                        buyweight: {
                          ...(prev?.sheep?.buyweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.buyWeights?.[1]?.[1]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.sheep?.buyweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        sheep: {
                          ...(prev?.sheep || {}),
                          buyweight: {
                            ...(prev?.sheep?.buyweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
