import { EditableItem } from "components";
import { useLoaderContext } from "hooks/useLoaderContext";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "request";
import { layout_table } from "utils/layout";
import parse from "html-react-parser"

export default function EquipmentNeedsPage() {
  const { id } = useParams();
  const [data, setData] = useState();
  const loader = useLoaderContext();

  const fetchData = async () => {
    if (id !== "new") {
      loader.start();
      const resData = await request("reports@cashFlow", { id: +id });
      setData(resData);
      loader.stop();
    } else {
      setData({});
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) return <h1>Loading...</h1>

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted">
            Monthly Liquidity Projection
          </p>
        </div>
        {/* begin::Table */}
        <div className="card m-5">
          <div className="card-body p-5">
            {/* <div class="table-responsive mt-5"> */}
            {/* begin::Table */}
            <div className="table-responsive">{parse(layout_table(data))}</div>
            {/* end::Table */}
            {/* begin::Moda */}
            <div className="modal fade" tabIndex={-1} id="kt_modal_stacked_1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title">
                      Selling, General &amp; Administrative
                    </h3>
                    {/*begin::Close*/}
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span className="svg-icon svg-icon-2x">
                        <i className="fa-solid fa-x" />
                      </span>
                    </div>
                    {/*end::Close*/}
                  </div>
                  <div className="modal-body h-400px scroll">
                    <ul className="nav nav-tabs mb-5 fs-6">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_1"
                        >
                          General info
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_2"
                        >
                          Loan calculator
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#kt_tab_pane_3"
                        >
                          Grace period
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_tab_pane_1"
                        role="tabpanel"
                      >
                        <h4 className="my-4">
                          Set all cells to the same value:
                        </h4>
                        <span className="nav nav-tabs mb-5 fs-6" />
                        <label className="fw-bold">This much every month</label>
                        <div className="d-flex align-items-center gap-5 mt-3 mb-5">
                          <input
                            type="number"
                            name="number"
                            className="form-control w-250px"
                          />
                          <span>EUR</span>
                          <a className="btn btn-success" href="#apply">
                            Apply
                          </a>
                        </div>
                        <span className="nav nav-tabs mb-5 fs-6" />
                        <label className="fw-bold">
                          This much total for the projection period
                        </label>
                        <div className="d-flex align-items-center gap-5 mt-3">
                          <input
                            type="number"
                            name="number"
                            className="form-control w-250px"
                          />
                          <span>EUR</span>
                          <a className="btn btn-success" href="#apply">
                            Apply
                          </a>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_pane_2"
                        role="tabpanel"
                      >
                        <form>
                          <div className="d-flex align-items-center gap-5">
                            <label className="min-w-150px">
                              Disbursement month
                            </label>
                            <select className="form-select form-select-lg mb-3 w-200px">
                              <option value={202205}>May '22</option>
                              <option value={202206}>June '22</option>
                              <option value={202207}>July '22</option>
                              <option value={202208}>August '22</option>
                              <option value={202209}>September '22</option>
                              <option value={202210}>October '22</option>
                              <option value={202211}>November '22</option>
                              <option value={202212}>December '22</option>
                              <option value={202301}>January '23</option>
                              <option value={202302}>February '23</option>
                              <option value={202303}>March '23</option>
                              <option value={202304}>April '23</option>
                              <option value={202305}>May '23</option>
                              <option value={202306}>June '23</option>
                              <option value={202307}>July '23</option>
                              <option value={202308}>August '23</option>
                              <option value={202309}>September '23</option>
                              <option value={202310}>October '23</option>
                              <option value={202311}>November '23</option>
                              <option value={202312}>December '23</option>
                              <option value={202401}>January '24</option>
                              <option value={202402}>February '24</option>
                              <option value={202403}>March '24</option>
                              <option value={202404}>April '24</option>
                              <option value={202405}>May '24</option>
                              <option value={202406}>June '24</option>
                              <option value={202407}>July '24</option>
                              <option value={202408}>August '24</option>
                              <option value={202409}>September '24</option>
                              <option value={202410}>October '24</option>
                              <option value={202411}>November '24</option>
                              <option value={202412}>December '24</option>
                              <option value={202501}>January '25</option>
                              <option value={202502}>February '25</option>
                              <option value={202503}>March '25</option>
                              <option value={202504}>April '25</option>
                              <option value={202505}>May '25</option>
                              <option value={202506}>June '25</option>
                              <option value={202507}>July '25</option>
                              <option value={202508}>August '25</option>
                              <option value={202509}>September '25</option>
                              <option value={202510}>October '25</option>
                              <option value={202511}>November '25</option>
                              <option value={202512}>December '25</option>
                              <option value={202601}>January '26</option>
                              <option value={202602}>February '26</option>
                              <option value={202603}>March '26</option>
                              <option value={202604}>April '26</option>
                              <option value={202605}>May '26</option>
                              <option value={202606}>June '26</option>
                              <option value={202607}>July '26</option>
                              <option value={202608}>August '26</option>
                              <option value={202609}>September '26</option>
                              <option value={202610}>October '26</option>
                              <option value={202611}>November '26</option>
                              <option value={202612}>December '26</option>
                              <option value={202701}>January '27</option>
                              <option value={202702}>February '27</option>
                              <option value={202703}>March '27</option>
                              <option value={202704}>April '27</option>
                              <option value={202705}>May '27</option>
                              <option value={202706}>June '27</option>
                              <option value={202707}>July '27</option>
                              <option value={202708}>August '27</option>
                              <option value={202709}>September '27</option>
                              <option value={202710}>October '27</option>
                              <option value={202711}>November '27</option>
                              <option value={202712}>December '27</option>
                              <option value={202801}>January '28</option>
                              <option value={202802}>February '28</option>
                              <option value={202803}>March '28</option>
                              <option value={202804}>April '28</option>
                            </select>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Total loan term
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>months</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Including grace period
                            </label>
                            <a
                              className="nav-link text-info"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_2"
                            >
                              Settings
                            </a>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Annual interest rate
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>%</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Loan amount (th. EUR)
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>EUR</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">Loan type</label>
                            <div className="d-flex flex-column">
                              <label className="my-3 d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="calcoption"
                                  id="loan-type1"
                                />
                                Standard
                              </label>
                              <label className="d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="calcoption"
                                  id="loan-type2"
                                />
                                Annual
                              </label>
                            </div>
                          </div>
                          <a className="btn btn-success my-5" href="#calc-loan">
                            Calculate loan
                          </a>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="kt_tab_pane_3"
                        role="tabpanel"
                      >
                        <form>
                          <div className="my-5">
                            <label className="d-flex align-items-center gap-3">
                              <input
                                type="radio"
                                name="graceoption"
                                id="grace-type1"
                              />
                              <b>Simple</b>
                            </label>
                            <label className="mt-4">
                              Specify the number of months the client will pay
                              only interest after the loan is disbursed
                            </label>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label>Grace period</label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-150px"
                            />
                            <span>months</span>
                          </div>
                          <a
                            className="nav-link d-flex align-items-center gap-3 mt-5"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                          >
                            <i className="fa-sharp fa-solid fa-left-long" />
                            Loan calculator
                          </a>
                          <span className="nav nav-tabs my-5 fs-6" />
                          <div className="my-5">
                            <label className="d-flex align-items-center gap-3">
                              <input
                                type="radio"
                                name="graceoption"
                                id="grace-type1"
                              />
                              <b>Flexible</b>
                            </label>
                            <label className="mt-4">
                              Specify the months when the client will pay only
                              interest (use Ctrl+click to select several rows)
                            </label>
                          </div>
                          <div>
                            <label>Grace months</label>
                            <textarea
                              className="form-control resize-none mt-4"
                              rows={3}
                              cols="50px"
                              defaultValue={""}
                            />
                          </div>
                          <a
                            className="nav-link d-flex align-items-center gap-3 mt-5"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                          >
                            <i className="fa-sharp fa-solid fa-left-long" />
                            Loan calculator
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer bg-light">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cencel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal */}
            {/* begin::Moda */}
            <div
              className="modal fade"
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                      Loan calculator
                    </h5>
                    {/*begin::Close*/}
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span className="svg-icon svg-icon-2x">
                        <i className="fa-solid fa-x" />
                      </span>
                    </div>
                    {/*end::Close*/}
                  </div>
                  <div className="modal-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          General info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Grace period
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <form className="mt-5">
                          <div className="d-flex align-items-center gap-5">
                            <div className="min-w-150px">
                              <label>Disbursement month</label>
                            </div>
                            <div>
                              <select className="form-select form-select-lg mb-3 w-200px">
                                <option value={202205}>May '22</option>
                                <option value={202206}>June '22</option>
                                <option value={202207}>July '22</option>
                                <option value={202208}>August '22</option>
                                <option value={202209}>September '22</option>
                                <option value={202210}>October '22</option>
                                <option value={202211}>November '22</option>
                                <option value={202212}>December '22</option>
                                <option value={202301}>January '23</option>
                                <option value={202302}>February '23</option>
                                <option value={202303}>March '23</option>
                                <option value={202304}>April '23</option>
                                <option value={202305}>May '23</option>
                                <option value={202306}>June '23</option>
                                <option value={202307}>July '23</option>
                                <option value={202308}>August '23</option>
                                <option value={202309}>September '23</option>
                                <option value={202310}>October '23</option>
                                <option value={202311}>November '23</option>
                                <option value={202312}>December '23</option>
                                <option value={202401}>January '24</option>
                                <option value={202402}>February '24</option>
                                <option value={202403}>March '24</option>
                                <option value={202404}>April '24</option>
                                <option value={202405}>May '24</option>
                                <option value={202406}>June '24</option>
                                <option value={202407}>July '24</option>
                                <option value={202408}>August '24</option>
                                <option value={202409}>September '24</option>
                                <option value={202410}>October '24</option>
                                <option value={202411}>November '24</option>
                                <option value={202412}>December '24</option>
                                <option value={202501}>January '25</option>
                                <option value={202502}>February '25</option>
                                <option value={202503}>March '25</option>
                                <option value={202504}>April '25</option>
                                <option value={202505}>May '25</option>
                                <option value={202506}>June '25</option>
                                <option value={202507}>July '25</option>
                                <option value={202508}>August '25</option>
                                <option value={202509}>September '25</option>
                                <option value={202510}>October '25</option>
                                <option value={202511}>November '25</option>
                                <option value={202512}>December '25</option>
                                <option value={202601}>January '26</option>
                                <option value={202602}>February '26</option>
                                <option value={202603}>March '26</option>
                                <option value={202604}>April '26</option>
                                <option value={202605}>May '26</option>
                                <option value={202606}>June '26</option>
                                <option value={202607}>July '26</option>
                                <option value={202608}>August '26</option>
                                <option value={202609}>September '26</option>
                                <option value={202610}>October '26</option>
                                <option value={202611}>November '26</option>
                                <option value={202612}>December '26</option>
                                <option value={202701}>January '27</option>
                                <option value={202702}>February '27</option>
                                <option value={202703}>March '27</option>
                                <option value={202704}>April '27</option>
                                <option value={202705}>May '27</option>
                                <option value={202706}>June '27</option>
                                <option value={202707}>July '27</option>
                                <option value={202708}>August '27</option>
                                <option value={202709}>September '27</option>
                                <option value={202710}>October '27</option>
                                <option value={202711}>November '27</option>
                                <option value={202712}>December '27</option>
                                <option value={202801}>January '28</option>
                                <option value={202802}>February '28</option>
                                <option value={202803}>March '28</option>
                                <option value={202804}>April '28</option>
                              </select>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Total loan term
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>months</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Including grace period
                            </label>
                            <a
                              className="nav-link text-info"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_2"
                            >
                              Settings
                            </a>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Annual interest rate
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>%</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">
                              Loan amount (th. EUR)
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-200px"
                            />
                            <span>EUR</span>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label className="min-w-150px">Loan type</label>
                            <div className="d-flex flex-column">
                              <label className="my-3 d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="calcoption"
                                  id="loan-type1"
                                />
                                Standard
                              </label>
                              <label className="d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="calcoption"
                                  id="loan-type2"
                                />
                                Annual
                              </label>
                            </div>
                          </div>
                          <span className="nav nav-tabs my-5 fs-6" />
                          <p>
                            Attention. A new loan calculation will erase
                            existing loan assumptions.
                          </p>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <form>
                          <div className="my-5">
                            <label className="d-flex align-items-center gap-3">
                              <input
                                type="radio"
                                name="graceoption"
                                id="grace-type1"
                              />
                              <b>Simple</b>
                            </label>
                            <label className="mt-4">
                              Specify the number of months the client will pay
                              only interest after the loan is disbursed
                            </label>
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-5">
                            <label>Grace period</label>
                            <input
                              type="number"
                              name="number"
                              className="form-control w-150px"
                            />
                            <span>months</span>
                          </div>
                          <a
                            className="nav-link d-flex align-items-center gap-3 mt-5"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                          >
                            <i className="fa-sharp fa-solid fa-left-long" />
                            Loan calculator
                          </a>
                          <span className="nav nav-tabs my-5 fs-6" />
                          <div className="my-5">
                            <label className="d-flex align-items-center gap-3">
                              <input
                                type="radio"
                                name="graceoption"
                                id="grace-type1"
                              />
                              <b>Flexible</b>
                            </label>
                            <label className="mt-4">
                              Specify the months when the client will pay only
                              interest (use Ctrl+click to select several rows)
                            </label>
                          </div>
                          <div>
                            <label>Grace months</label>
                            <textarea
                              className="resize-none form-control mt-4"
                              rows={3}
                              cols="50px"
                              defaultValue={""}
                            />
                          </div>
                          <a
                            className="nav-link d-flex align-items-center gap-3 mt-5"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_2"
                          >
                            <i className="fa-sharp fa-solid fa-left-long" />
                            Loan calculator
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer bg-light">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cencel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                    <button type="button" className="btn btn-success">
                      Calculate loan
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal */}
            {/* </div> */}
          </div>
        </div>
        {/* end::Table */}
      </div>
    </div>
  );
}
