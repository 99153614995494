import React from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Dairy({ selects }) {
  const { control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  return (
    <div className="tab-pane fade" id="kt_tab_pane_b" role="tabpanel">
      <div className="col-12">
        <h3>Herd as of starting date</h3>
        {fields.map((field, idx) => (
          <React.Fragment key={field.id}>
            <table className="mt-3">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Weight, kg</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0">
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width"
                    />
                  </td>
                  <td className="d-flex align-items-center gap-3">
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width"
                    />
                    <button className="remove-btn" onClick={() => remove(idx)}>
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="accordion accordion-icon-toggle"
              id="kt_accordion_2"
            >
              <div className="mb-5">
                <div
                  className="accordion-header py-3 d-flex collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item_2"
                >
                  <a
                    className="fw-semibold mb-0 ms-4 text-center"
                    href="#create"
                  >
                    How To Create Channel
                  </a>
                </div>
                <div
                  id="kt_accordion_2_item_2"
                  className="collapse fs-6"
                  data-bs-parent="#kt_accordion_2"
                >
                  <table>
                    <thead>
                      <tr>
                        <th>buy date</th>
                        <th>sell date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className="form-control form-control-sm input-width flatpickr-input"
                            placeholder="Pick a date"
                            id="kt_daterangepicker_3"
                            type="text"
                            readOnly="readonly"
                          />
                        </td>
                        <td>
                          <input
                            className="form-control form-control-sm input-width flatpickr-input active"
                            placeholder="Pick a date"
                            id="kt_daterangepicker_3"
                            type="text"
                            readOnly="readonly"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th>Breed</th>
                        <th>Age, month</th>
                        <th>Status</th>
                        <th>month</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <select
                            className="form-select form-select-sm "
                            aria-label="Default select example"
                          >
                            <option value={110}>yield 110</option>
                            <option value={142}>yield 142</option>
                            <option value={177}>yield 177</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            pattern="[0-9]{10}"
                            className="form-control form-control-sm input-width "
                            placeholder={1000}
                          />
                        </td>
                        <td>
                          <select
                            className="form-select form-select-sm "
                            aria-label="Default select example"
                          >
                            <option value="sucking">sucking</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            pattern="[0-9]{10}"
                            className="form-control form-control-sm input-width "
                            placeholder={3}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h3 className="my-4 mx-3">Calve sale settings</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Age, months</th>
                        <th>
                          <label htmlFor="in-use">In use</label>
                        </th>
                        <th>Weight, ks</th>
                        <th>
                          <label htmlFor="in-use2">In use</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="number"
                            min={0}
                            pattern="[0-9]{10}"
                            className="form-control form-control-sm input-width input-width"
                            placeholder={3}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="in-use"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            pattern="[0-9]{10}"
                            className="form-control form-control-sm input-width input-width"
                            placeholder={3}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="in-use2"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        <hr />
        <button
          onClick={append}
          className="btn btn-success mt-3 d-block btn-sm btn-sm btn-sm"
        >
          <i className="fa-solid fa-plus" />
        </button>
      </div>
    </div>
  );
}
