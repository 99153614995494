/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { request } from "request";

export default function Companies() {
  const [companies, setCompanies] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const paginationCount = useMemo(
    () => Math.ceil((companies?.pagination?.total || 0) / pageCount),
    [companies, pageCount]
  );

  const fetchCompanies = async () => {
    const data = await request("companies@index", {
      per_page: pageCount,
      page: pageNum,
    });

    setCompanies(data);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [pageNum, pageCount]);

  useEffect(() => {
    setPageNum(1);
  }, [pageCount]);

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                {/*begin::Table head*/}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Name</th>
                    <th className="min-w-140px">District</th>
                    <th className="min-w-120px">Province</th>
                    <th className="min-w-120px">User</th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody>
                  {companies?.data?.map((company) => (
                    <tr key={company.id}>
                      <td>
                        <Link
                          to={`/client/${company.id}`}
                          className="text-hover-dark"
                        >
                          {company.name.en}
                        </Link>
                      </td>
                      <td>{company?.district.name}</td>
                      <td>{company.province.name}</td>
                      <td>{company.user.name}</td>
                    </tr>
                  ))}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            <div className="row mb-2">
              <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                <div
                  className="dataTables_length"
                  id="kt_permissions_table_length"
                >
                  <label>
                    <select
                      name="kt_permissions_table_length"
                      aria-controls="kt_permissions_table"
                      className="form-select form-select-sm form-select-solid"
                      onChange={(e) => setPageCount(+e.target.value || 10)}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </label>
                  Showing {pageNum + 1} to {companies?.data?.length} of{" "}
                  {companies?.pagination?.total} records
                </div>
              </div>
              <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="kt_permissions_table_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        pageNum === 1 ? "disabled" : ""
                      }`}
                      id="kt_permissions_table_previous"
                    >
                      <button
                        className="page-link"
                        onClick={() => setPageNum((prev) => prev - 1)}
                      >
                        <i className="previous" />
                      </button>
                    </li>
                    {[...new Array(paginationCount)].map((_, i) => (
                      <li
                        key={i}
                        className={`paginate_button page-item ${
                          pageNum - 1 === i ? "active" : ""
                        }`}
                      >
                        <button
                          key={i}
                          className="page-link"
                          onClick={() => setPageNum(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        pageNum === paginationCount ? "disabled" : ""
                      }`}
                      id="kt_permissions_table_next"
                    >
                      <button
                        onClick={() => setPageNum((prev) => prev + 1)}
                        className="page-link"
                      >
                        <i className="next" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
