import React from "react";
import { getDateInputValue } from "utils/functions";

export default function General({ setUpdatedData, data }) {
  return (
    <div
      className="tab-pane fade show active w-25"
      id="kt_tab_pane_1"
      role="tabpanel"
    >
      <div className="form-group">
        <span className="d-block mb-2">Start date</span>
        <div className="mb-10">
          <input
            className="form-control form-control-sm"
            placeholder="Pick a date"
            id="kt_datepicker_1"
            type="date"
            defaultValue={getDateInputValue(data?.start_date)}
            onChange={(e) =>
              setUpdatedData({
                start_date: new Date(e.target.value),
              })
            }
          />
        </div>
      </div>
      <div className="form-group mb-10">
        <span>Duration</span>
        <input
          type="number"
          min={0}
          pattern="[0-9]{10}"
          className="form-control form-control-sm mt-3"
          placeholder={1000}
          defaultValue={data?.duration}
          onChange={(e) =>
            setUpdatedData({
              duration: e.target.value,
            })
          }
        />
      </div>
      <div className="form-group">
        <label className="form-label">Cash balance at start</label>
        <input
          type="number"
          min={0}
          pattern="[0-9]{10}"
          className="form-control form-control-sm mt-3"
          placeholder={1000}
          defaultValue={data?.cash}
          onChange={(e) =>
            setUpdatedData({
              cash: e.target.value,
            })
          }
        />
      </div>
      <div className="form-group mt-10">
        <label htmlFor="" className="form-label">
          Loan Details
        </label>
        <textarea
          className="form-control form-control-sm"
          data-kt-autosize="true"
          placeholder="buying seedlings"
          defaultValue={data?.extra_data?.loan?.purpose}
          onChange={(e) =>
            setUpdatedData({
              extra_data: {
                ...(data?.extra_data || {}),
                loan: {
                  ...(data?.extra_data?.loan || {}),
                  purpose: e.target.value,
                },
              },
            })
          }
        />
      </div>
    </div>
  );
}
