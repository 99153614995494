import React from "react";

export default function OperationsSummary() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted mb-2">
            Summary of operations
          </p>
          <p className="text-muted">SOUR CHERRY</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="table-responsive">
              <table className="table table-row-bordered table-center table-striped border border-gray-300 table-row-gray-300 align-middle gs-0 gy-3">
                <thead className="border border-gray-300">
                  <tr className="fw-semibold fs-6 text-gray-800">
                    <th>Operation</th>
                    <th>Start date</th>
                    <th>
                      <a href="#costs">Material costs, EUR</a>
                    </th>
                    <th>
                      <a href="#lobar">Labor cost, EUR</a>
                    </th>
                    <th>
                      <a href="#fuel">Fuel &amp; oil, EUR</a>
                    </th>
                    <th>
                      <a href="#cost-of-services">Cost of services, EUR</a>
                    </th>
                  </tr>
                </thead>
                <tbody className="border border-gray-300">
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Application of plant protection agents (orchards)
                    </td>
                    <td>May, 2022</td>
                    <td>152</td>
                    <td />
                    <td />
                    <td>30</td>
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Manual Harvesting and transportation sour cherry
                    </td>
                    <td>May, 2022</td>
                    <td />
                    <td>1000</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Application of plant protection agents (orchards)
                    </td>
                    <td>May, 2022</td>
                    <td>152</td>
                    <td />
                    <td />
                    <td>30</td>
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Manual Harvesting and transportation sour cherry
                    </td>
                    <td>May, 2022</td>
                    <td />
                    <td>1000</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Application of plant protection agents (orchards)
                    </td>
                    <td>May, 2022</td>
                    <td>152</td>
                    <td />
                    <td />
                    <td>30</td>
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td>May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">Drip irrigation</td>
                    <td className="text-end">May, 2022</td>
                    <td />
                    <td>20</td>
                    <td />
                    <td />
                  </tr>
                  <tr className="text-end">
                    <td className="text-start">
                      Manual Harvesting and transportation sour cherry
                    </td>
                    <td>May, 2022</td>
                    <td />
                    <td>1000</td>
                    <td />
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
