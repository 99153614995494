import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { sendRequest } from "utils/functions";

export default function Login() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async (data) => {
    setLoading(true);

    try {
      await sendRequest("/login", {
        method: "POST",
        body: JSON.stringify(data),
      });
      
      navigate("/");
    } catch (ex) {
      console.error(ex);
    }

    setLoading(false);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid flex-lg-row">
      <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div className="d-flex flex-center flex-lg-start flex-column position-relative"></div>
      </div>
      <div className="d-flex flex-center w-lg-50 p-10">
        <div className="card rounded-3 w-md-550px">
          <div className="card-body p-10 p-lg-20">
            <form
              onSubmit={handleSubmit(submit)}
              className="form w-100"
              noValidate="novalidate"
              id="kt_sign_in_form"
              data-kt-redirect-url="/metronic8/demo1/../demo1/index.html"
              action="#"
            >
              <div className="text-center mb-11">
                <a href="#logo" className="mb-7">
                  <img
                    alt="Logo"
                    src="/assets/media/logos/clara-default.svg"
                    className="theme-light-show h-35px"
                  />
                  <img
                    alt="Logo"
                    src="/assets/media/logos/clara-dark.svg"
                    className="theme-dark-show h-35px"
                  />
                </a>
                <h2 className="text-gray-600 fw-normal my-5">
                  Please provide your username and password
                </h2>
              </div>
              <div className="fv-row mb-4">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  autoComplete="off"
                  className={`form-control bg-transparent ${
                    errors.username?.message && "error-input"
                  }`}
                  {...register("username", {
                    required: "username is required!",
                  })}
                />
                {errors.username && (
                  <span className="error-message">
                    {errors.username?.message}
                  </span>
                )}
              </div>
              <div className="fv-row mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  autoComplete="off"
                  className={`form-control bg-transparent ${
                    errors.password?.message && "error-input"
                  }`}
                  {...register("password", {
                    required: "password is required!",
                  })}
                />
                {errors.password && (
                  <span className="error-message">
                    {errors.password?.message}
                  </span>
                )}
              </div>
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div />
                <Link to="/password-recovery" className="link-primary">
                  Forgot Password ?
                </Link>
              </div>
              <div className="d-grid mb-10">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {!loading ? (
                    <span className="indicator-label">Sign In</span>
                  ) : (
                    <span className="indicator-label">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
