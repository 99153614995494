import { BACKEND_URL } from "constants";

export const renderTableNumbers = (data) =>
  data.map((item, i) => (
    <td key={i} className={item?.state ? `bg-light-${item?.state}` : ""}>
      {item?.value === undefined ? item : item.value}
    </td>
  ));

export const getDateInputValue = (dateObj) => {
  const local = new Date(dateObj);
  const month = local.getMonth().toString();
  const date = local.getDate().toString();
  return `${local.getFullYear()}-${month.length < 2 ? `0${month}` : month}-${
    date.length < 2 ? `0${date}` : date
  }`;
};

export const getToken = () => {
  if (typeof document !== "undefined") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; XSRF-TOKEN=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
};

export function sendRequest(url, options) {
  const csrfToken = getToken();
  return fetch(BACKEND_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-XSRF-TOKEN": decodeURIComponent(csrfToken),
    },
    credentials: "include",
    ...options,
  });
}
