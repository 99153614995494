import React, { useRef, useMemo, useContext } from "react";
import LoadingBar from "react-top-loading-bar";
import { createContext } from "react";

const LoaderContext = createContext();

export function useLoaderContext() {
  return useContext(LoaderContext);
}

export default function LoaderProvider({ children }) {
  const loaderRef = useRef(null);

  const contextValue = useMemo(
    () => ({
      start: () => loaderRef.current?.continuousStart(),
      stop: () => loaderRef.current?.complete(),
    }),
    []
  );

  return (
    <LoaderContext.Provider value={contextValue}>
      <LoadingBar color="#8800ff" ref={loaderRef} />
      {children}
    </LoaderContext.Provider>
  );
}
