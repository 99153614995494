import { EditableItem } from "components";
import React from "react";

export default function SeedsSettings() {
  return (
    <div className="container-xxl custom-title">
      <div className="m-5">
        <h1 className="text-dark fw-bold">Farmer444</h1>
        <h1 className="text-dark fw-bold">Settings for seeds</h1>
        <p className="breadcrumb-item text-muted">Inventory and price</p>
      </div>
      <div className="card m-5">
        <div className="card-body p-5">
          <div className="table-responsive">
            <table className="table table-row-bordered align-middle gs-0 gy-3 border border-gray-300">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border border-gray-300">
                  <th className="min-w-300px">Name</th>
                  <th className="min-w-100px">Opening balance, kg</th>
                  <th className="min-w-100px">Required volume, kg</th>
                  <th className="min-w-100px">Suggested price, EUR/kg</th>
                  <th className="min-w-100px">Corrected price, EUR/kg</th>
                  <th className="min-w-100px">To be paid, EUR</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-gray-300">
                  <td>
                    Seeds of winter wheat - international/local production
                  </td>
                  <td className="bg-light-warning">
                    <EditableItem start>-</EditableItem>
                  </td>
                  <td>5000.00</td>
                  <td>0.650</td>
                  <td className="bg-light-warning">
                    <EditableItem start>0.650</EditableItem>
                  </td>
                  <td>3250.00</td>
                </tr>
                <tr className="border border-gray-300">
                  <td>Seeds of maize - international production</td>
                  <td className="bg-light-warning">
                    <EditableItem start>-</EditableItem>
                  </td>
                  <td>200.00</td>
                  <td>1.300</td>
                  <td className="bg-light-warning">
                    <EditableItem start>1.300</EditableItem>
                  </td>
                  <td>260.00</td>
                </tr>
                <tr className="border border-gray-300">
                  <td colSpan={5}>Total, EUR`</td>
                  <td className="bg-light-warning">
                    <EditableItem start>3510.00</EditableItem>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
