import React, { useEffect, useRef, useState } from "react";

export default function EditableItem({ children, start }) {
  const [value, setValue] = useState(children);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    const inputParent = inputRef.current?.parentElement;
    if (editing) {
      inputParent.classList.replace("bg-light-warning", "editing")
    } else {
      inputParent.classList.replace("editing", "bg-light-warning")
    }
  }, [editing]);

  return (
    <input
      ref={inputRef}
      onFocus={() => setEditing(true)}
      onBlur={() => setEditing(false)}
      // disabled={!editing}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{
        padding: 0,
        textAlign: start ? "start" : "end",
        width: "10px",
        minWidth: "100%",
        background: "transparent",
        outline: "0",
        border: "0",
        color: "#92929f",
        transition: "300ms"
        // backgroundColor: editing ? "#ccc" : "transparent"
      }}
    />
  );
}
