import SelectMenu from "common/SelectMenu";
import React, { useEffect, useState } from "react";

export default function Crops({
  setUpdatedData,
  data,
  crops,
  orchards,
  equipments,
  subequipments,
}) {
  const [selectedEq, setSelectedEq] = useState("");
  const [cropInputs, setCropInputs] = useState([]);
  const [orchardInputs, setOrchardInputs] = useState([]);
  const [eqInputs, setEqInputs] = useState([]);

  const removeInput = (idx, data, setData) => {
    const oldData = [...(data || [])];
    oldData.splice(idx, 1);
    setData(oldData);
  };

  const orchardOnChange = (idx, key, value) => {
    const data = [...(orchardInputs || [])];
    data[idx][key] = value;
    setOrchardInputs(data);
  };

  useEffect(() => {
    setCropInputs(data?.crops?.crops || []);
    setOrchardInputs(data?.crops?.orchards || []);
    setEqInputs(data?.crops?.equipments || []);
  }, [data]);

  return (
    <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
      <div className="row">
        <div className="col-6">
          <h4>Field crops</h4>
          <div className="form-group">
            <div className="mb-7 w-50">
              <SelectMenu
                searchable
                options={Object.values(crops?.result || {}).map((crop) => ({
                  value: crop.CropID,
                  label: crop.name,
                }))}
                placeholder="Choose Crops"
                onChange={setCropInputs}
                selecteds={cropInputs}
              />
            </div>
            <table className="table border border-gray-300">
              <thead className="border border-gray-300">
                <tr>
                  <th />
                  <th>Existing crop</th>
                  <th>Area, Ha</th>
                  <th colSpan={2}>Expected yield, t/Ha</th>
                </tr>
              </thead>
              <tbody className="border border-gray-300">
                {cropInputs.map((field, idx) => (
                  <tr key={idx}>
                    <td>{field.label}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name="check"
                        className="form-check-input"
                        value={cropInputs[idx]?.extra?.existing}
                        onChange={(e) =>
                          setCropInputs((prev) => {
                            const data = [...prev];
                            data[idx] = {
                              ...data[idx],
                              extra: {
                                ...(data[idx]?.extra || {}),
                                existing: e.target.checked ? 1 : 0,
                              },
                            };
                            return data;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        min={0}
                        pattern="[0-9]{10}"
                        name="number"
                        className="form-control form-control-sm input-width input-width"
                        placeholder={1}
                        value={cropInputs[idx].area}
                        onChange={(e) =>
                          setCropInputs((prev) => {
                            const data = [...prev];
                            data[idx] = { ...data[idx], area: +e.target.value };
                            return data;
                          })
                        }
                      />
                    </td>
                    <td className="d-flex gap-4 align-items-center">
                      <input
                        type="number"
                        min={0}
                        pattern="[0-9]{10}"
                        name="number"
                        className="form-control form-control-sm input-width input-width"
                        placeholder={1}
                        value={cropInputs[idx].yield}
                        onChange={(e) =>
                          setCropInputs((prev) => {
                            const data = [...prev];
                            data[idx] = {
                              ...data[idx],
                              yield: +e.target.value,
                            };
                            return data;
                          })
                        }
                      />
                      <button
                        type="button"
                        className="btn btn-danger rounded-circle p-3"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="2-4-5"
                      />
                      <button
                        onClick={() =>
                          removeInput(idx, cropInputs, setCropInputs)
                        }
                        className="remove-btn"
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <AddButton onClick={() =()} /> */}
            <hr className="my-7" />
          </div>
          <h4>Orchards and Multiyear plants</h4>
          <div className="form-group">
            <div className="mb-7 w-50">
              <SelectMenu
                searchable
                options={Object.values(orchards?.result || {}).map(
                  (orchard) => ({
                    value: orchard.CropID,
                    label: orchard.name,
                    schemes: orchard.schemes,
                  })
                )}
                placeholder="Choose Crops"
                onChange={setOrchardInputs}
                selecteds={orchardInputs}
              />
            </div>
            <table className="table mb-10 ">
              <thead className="border border-gray-300">
                <tr>
                  <th className="border-0">Crop</th>
                  <th className="border-0">Area, Ha</th>
                  <th className="border-0">Expected yield, t/Ha</th>
                  <th className="border-0" colSpan={2}>
                    Orchard age, years
                  </th>
                </tr>
              </thead>
              <tbody className="border border-gray-300">
                {orchardInputs.map((field, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td className="border-0">{field.label}</td>
                      <td className="border-0 text-center">
                        <input
                          type="number"
                          min={0}
                          pattern="[0-9]{10}"
                          name="number"
                          className="form-control form-control-sm input-width input-width"
                          placeholder={1}
                          value={orchardInputs[idx].area}
                          onChange={(e) =>
                            orchardOnChange(idx, "area", +e.target.value)
                          }
                        />
                      </td>
                      <td className="border-0 ">
                        <div className="d-flex gap-4 align-items-center">
                          <input
                            type="number"
                            min={0}
                            pattern="[0-9]{10}"
                            name="number"
                            className="form-control form-control-sm input-width input-width"
                            placeholder={1}
                            value={orchardInputs[idx].yield}
                            onChange={(e) =>
                              orchardOnChange(idx, "yield", +e.target.value)
                            }
                          />
                          <button
                            type="button"
                            className="btn btn-danger rounded-circle p-3"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="2-4-5"
                          />
                        </div>
                      </td>
                      <td className="border-0 d-flex gap-4 align-items-center">
                        <input
                          type="number"
                          min={0}
                          pattern="[0-9]{10}"
                          name="number"
                          className="form-control form-control-sm input-width input-width"
                          placeholder={1}
                          value={orchardInputs[idx].age}
                          onChange={(e) =>
                            orchardOnChange(idx, "age", +e.target.value)
                          }
                        />
                        <button
                          onClick={() =>
                            removeInput(idx, orchardInputs, setOrchardInputs)
                          }
                          className="remove-btn"
                        >
                          <i className="fas fa-trash" />
                        </button>
                      </td>
                    </tr>
                    <tr className="border-0" style={{ borderTop: 0 }}>
                      <td
                        colSpan={4}
                        className="accordion-item border-0 remove-border-t"
                        style={{ width: "100%" }}
                      >
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button my-3"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${idx}`}
                          >
                            Additional Properties
                          </button>
                        </h2>
                        <div
                          id={`collapse${idx}`}
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body d-flex align-items-center">
                            <div>
                              <p>Existing crop</p>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={orchardInputs[idx].extra?.existing}
                                onChange={(e) =>
                                  setOrchardInputs((prev) => {
                                    const data = [...prev];
                                    data[idx] = {
                                      ...data[idx],
                                      extra: {
                                        ...(data[idx]?.extra || {}),
                                        existing: e.target.checked ? 1 : 0,
                                      },
                                    };
                                    return data;
                                  })
                                }
                              />
                            </div>
                            <div className="ms-5">
                              <p className="m-0">Planting scheme</p>
                              <select
                                className=" form-control form-select-sm"
                                value={orchardInputs[idx].density}
                                onChange={(e) =>
                                  orchardOnChange(
                                    idx,
                                    "density",
                                    e.target.value
                                  )
                                }
                              >
                                {Object.entries(field.schemes || {}).map(
                                  ([label, value], idx) => (
                                    <option key={idx} value={value}>
                                      {label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-6">
          <label className="mb-4">
            Auto sell production
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
            />
          </label>
          <h4>Equipment availability</h4>
          <div className="form-group">
            <div className="mb-7 d-flex gap-4">
              <SelectMenu
                onChange={(item) => setSelectedEq(item.value)}
                placeholder={
                  equipments?.result?.[selectedEq] || "Select Equipment"
                }
                singleChoice
                options={Object.keys(equipments?.result || {}).map((eq) => ({
                  label: equipments.result[eq],
                  value: eq,
                }))}
              />
              <SelectMenu
                onChange={setEqInputs}
                placeholder={"Select Equipment"}
                singleSelect
                selecteds={eqInputs}
                options={Object.values(
                  subequipments?.result?.[selectedEq] || {}
                ).map((eq) => ({ value: eq }))}
              />
            </div>
            <table className="table border border-gray-300">
              <thead className="border border-gray-300">
                <tr>
                  <th>Equipment</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody className="border border-gray-300">
                {eqInputs.map((field, idx) => (
                  <tr key={idx}>
                    <td>{field.value}</td>
                    <td className="d-flex align-items-center gap-4">
                      <input
                        type="number"
                        min={0}
                        pattern="[0-9]{10}"
                        name="number"
                        className="form-control form-control-sm input-width input-width"
                        placeholder={1}
                        value={eqInputs[idx]?.count}
                        onChange={(e) =>
                          setEqInputs((prev) => {
                            const data = [...prev];
                            data[idx].count = e.target.value;
                            return data;
                          })
                        }
                      />
                      <button
                        onClick={() => removeInput(idx, eqInputs, setEqInputs)}
                        className="remove-btn"
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

/*
<div className="card">
						<div className="card-header">
							<div className="card-title" data-toggle="collapse" data-target="#collapseOne1">
								Latest Orders
							</div>
						</div>
						<div id="collapseOne1" className="collapse show" data-parent="#accordionExample1">
							<div className="card-body">
								Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
							</div>
						</div>
					</div>
{crop_id: '31', area: '10', yield: '210', age: '10', density: '0',Orchards and Multiyear pl}

 */
