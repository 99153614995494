import React from "react";

export default function FuelCosts() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted">Summary of fuel costs</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="table-responsive">
              <table className="table table-row-bordered border border-gray-300 table-row-gray-300 align-middle gs-0 gy-3">
                <tbody className="border border-gray-300">
                  <tr>
                    <td>Operation</td>
                    <td>Interrow mowing</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>own equipment</td>
                  </tr>
                  <tr>
                    <td>Power equipment</td>
                    <td>tractors from 50 to 100 hp</td>
                  </tr>
                  <tr>
                    <td>Working equipment</td>
                    <td>Mower 1.65 m</td>
                  </tr>
                  <tr>
                    <td>Productivity, Ha/shift</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>Total workload, Ha</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Shifts</td>
                    <td>1.00</td>
                  </tr>
                  <tr>
                    <td>Fuel type</td>
                    <td>Diesel fuel</td>
                  </tr>
                  <tr>
                    <td>Fuel consumption, l/Ha</td>
                    <td>15.00</td>
                  </tr>
                  <tr>
                    <td>Total fuel volume, l</td>
                    <td>15.00</td>
                  </tr>
                  <tr>
                    <td>Fuel price, EUR/l</td>
                    <td>1.30</td>
                  </tr>
                  <tr>
                    <td>Total fuel cost, EUR</td>
                    <td>19.50</td>
                  </tr>
                  <tr>
                    <td>Total lubricants volume, l</td>
                    <td>0.77</td>
                  </tr>
                  <tr>
                    <td>Total lubricants cost, EUR</td>
                    <td>2.86</td>
                  </tr>
                  <tr>
                    <td>Total cost, EUR</td>
                    <td>22.36</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
