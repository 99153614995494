import React, { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Balance({ data, balanceData }) {
  const defaultData = useMemo(() => {
    const data = [[], [[], [], []], [[], []]];

    // 0
    balanceData?.[0]?.forEach((arr2) => {
      arr2.forEach((obj) => obj.description && data[0].push(obj));
    });

    // 1
    balanceData[1]?.[0]?.forEach(
      (obj) => obj.description && data[1][0].push(obj)
    );
    Object.values(balanceData?.[1]?.[1] || {})?.forEach(
      (obj) => obj.description && data[1][1].push(obj)
    );
    Object.values(balanceData?.[1]?.[2] || {})?.forEach(
      (obj) => obj.description && data[1][2].push(obj)
    );

    // 2
    balanceData[2]?.[0]?.forEach(
      (obj) => obj.description && data[2][0].push(obj)
    );
    Object.values(balanceData?.[2]?.[1] || {})?.forEach(
      (obj) => obj.description && data[2][1].push(obj)
    );

    return data;
  }, [balanceData]);

  const { control, register, watch } = useForm({
    defaultValues: {
      fields1: defaultData[0],
      fields2: defaultData[1][0],
      fields3: defaultData[1][1],
      fields4: defaultData[1][2],
      fields5: defaultData[2][0],
      fields6: defaultData[2][1],
    },
  });
  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({
    control,
    name: "fields1",
  });
  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: "fields2",
  });
  const {
    fields: fields3,
    append: append3,
    remove: remove3,
  } = useFieldArray({
    control,
    name: "fields3",
  });
  const {
    fields: fields4,
    append: append4,
    remove: remove4,
  } = useFieldArray({
    control,
    name: "fields4",
  });
  const {
    fields: fields5,
    append: append5,
    remove: remove5,
  } = useFieldArray({
    control,
    name: "fields5",
  });
  const {
    fields: fields6,
    append: append6,
    remove: remove6,
  } = useFieldArray({
    control,
    name: "fields6",
  });

  return (
    <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
      <div className="row">
        <div className="col-6">
          <div className="row">
            <div className="col-7">
              <h3>Assets</h3>
            </div>
            <div className="col-2 text-center">
              <h3>2022</h3>
            </div>
            <div className="col-2 text-center">
              <h3>2023</h3>
            </div>
          </div>
          <h4 className="text-center my-4">I. Non-current assets</h4>
          <div className="row gap-2 align-items-center">
            {fields1.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields1.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields1.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields1.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove1(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append1}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total non-current assets</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields1")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields1")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
          </div>
          <h4 className="text-center my-4">II. Сurrent assets</h4>
          <div className="row align-items-center">
            <b>Cash balance at start</b>
            {fields2.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields2.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields2.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields2.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove2(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append2}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total cash</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields2")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields2")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
          </div>
          <div className="row align-items-center">
            <b>Stock / Expenditure on future crops</b>
            {fields3.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields3.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields3.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields3.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove3(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append3}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total inventory</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields3")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields3")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
          </div>
          <div className="row align-items-center">
            <b>Accounts receivables (30 days)</b>
            {fields4.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields4.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields4.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields4.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove4(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append4}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total Trade Debtors</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields4")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields4")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total current assets</h4>
            </div>
            <div className="col-2 my-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={[
                  ...(watch("fields1") || []),
                  ...(watch("fields2") || []),
                  ...(watch("fields3") || []),
                  ...(watch("fields4") || []),
                ]?.reduce((total, currEl) => total + +currEl.year0, 0)}
                readOnly
              />
            </div>
            <div className="col-2 my-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={[
                  ...(watch("fields1") || []),
                  ...(watch("fields2") || []),
                  ...(watch("fields3") || []),
                  ...(watch("fields4") || []),
                ]?.reduce((total, currEl) => total + +currEl.year1, 0)}
                readOnly
              />
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total Assets</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-7">
              <h3>Liabilities &amp; equity</h3>
            </div>
            <div className="col-2 text-center">
              <h3>2022</h3>
            </div>
            <div className="col-2 text-center">
              <h3>2023</h3>
            </div>
          </div>
          <h4 className="text-center my-4">I. Liabilities</h4>
          <b>Current liabilities</b>
          <div className="row align-items-center mt-3">
            {fields5.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields5.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields5.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields5.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove5(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append5}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Current Liabilities</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields5")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields5")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
          </div>
          <h4 className="text-center my-4">Long-term liabilities</h4>
          <div className="row align-items-center">
            {fields6.map((field, idx) => (
              <React.Fragment key={field.id}>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    {...register(`fields6.${idx}.description`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields6.${idx}.year0`)}
                  />
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    {...register(`fields6.${idx}.year1`)}
                  />
                </div>
                <div className="col-1">
                  <button onClick={() => remove6(idx)} className="remove-btn">
                    <i className="fas fa-trash" />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="mt-4">
              <button
                onClick={append6}
                className="btn btn-success mb-3 d-lnline-block btn-sm"
              >
                <i className="fa-solid fa-plus" />
              </button>
            </div>
            <div className="col-7">
              <h4 className="mb-0">Long Term Debt</h4>
            </div>
            <div className="col-2 mb-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields6")?.reduce(
                  (total, currEl) => total + +currEl.year0,
                  0
                )}
                readOnly
              />
            </div>
            <div className="col-2 mb-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={watch("fields6")?.reduce(
                  (total, currEl) => total + +currEl.year1,
                  0
                )}
                readOnly
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-7">
              <h4 className="mb-0">Total liabilities</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={[
                  ...(watch("fields5") || []),
                  ...(watch("fields6") || []),
                ]?.reduce((total, currEl) => total + +currEl.year0, 0)}
                readOnly
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                value={[
                  ...(watch("fields5") || []),
                  ...(watch("fields6") || []),
                ]?.reduce((total, currEl) => total + +currEl.year1, 0)}
                readOnly
              />
            </div>
            <div className="col-7">
              <h4 className="mb-0">II. Equity</h4>
            </div>
            <div className="col-2 my-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
            <div className="col-2 my-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
            <div className="col-7">
              <h4 className="mb-0">Total liabilities &amp; equity</h4>
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
            <div className="col-2">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                className="form-control form-control-sm input-width "
                placeholder={0}
                disabled=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
