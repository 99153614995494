export function layout_table(structure) {
  let out = "<table";
  if (structure["id"]) out += ' id="' + structure["id"] + '"';
  if (structure["class"]) out += ' class="' + structure["class"] + '"';
  if (structure["style"]) out += ' style="' + structure["style"] + '"';
  out += "><thead>";
  if (structure["head_rows"]) {
    structure["head_rows"].forEach((tableRow) => {
      out += layout_tbl_row(tableRow);
    });
  }
  out = out.replaceAll("<td", "<th").replaceAll("/td>", "/th>");
  out += "</thead><tbody>";
  if (structure["body_rows"]) {
    structure["body_rows"].forEach((tableRow) => {
      out += layout_tbl_row(tableRow);
    });
  }
  out += "</tbody></table>";
  return out;
}

export function layout_tbl_row(structure) {
  let indent = "";
  let out = "<tr";
  let hide;

  if (structure["hide"]) {
    hide = ";display: none;";
  }
  if (structure["indent"]) {
    switch (structure["indent"]) {
      case 1:
        indent = " indent-small";
        break;
      case 2:
        indent = " indent-medium";
        break;
      default:
        break;
    }
  }

  if (structure["id"]) out += ' id="' + structure["id"] + '"';
  if (structure["class"]) {
    out += ' class="' + structure["class"] + indent + '"';
  } else if (indent) {
    out += ' class="' + indent + '"';
  }

  if (structure["style"]) {
    out += ' style="' + structure["style"] + hide + '"';
  } else if (hide) {
    out += ' style="' + hide + '"';
  }

  out += ">";
  if (structure["cells"]) {
    structure["cells"].forEach((tableCell) => {
      out += layout_tbl_cell(tableCell);
    });
  }
  out += "</tr>";
  return out;
}

export function layout_tbl_cell(structure) {
  if (structure["skip"]) {
    return;
  }
  if (
    structure["val"] &&
    typeof structure["val"] === "number" &&
    structure["val"] < 0 &&
    structure["val"] !== ""
  ) {
    structure["class"] =
      (structure["class"] ? structure["class"] : "") + " negative";
  }
  let out = "<td";
  if (structure["id"]) out += ' id="' + structure["id"] + '"';
  if (structure["class"]) out += ' class="' + structure["class"] + '"';
  if (structure["style"]) out += ' style="' + structure["style"] + '"';
  if (structure["rowspan"]) out += ' rowspan="' + structure["rowspan"] + '"';
  if (structure["colspan"]) out += ' colspan="' + structure["colspan"] + '"';
  out += ">";

  let type = structure["type"] || "general";
  if (structure["val"]) {
    let val = structure["val"];
    switch (type) {
      case "abbr":
        out += layout_abbr(val, structure.hint);
        break;
      case "plus":
        out += layout_plus(val);
        break;
      case "asmp":
        out += layout_asmp(val, structure.view, structure.title);
        break;
      case "source":
        out += layout_source(val, structure.inner_id);
        break;
      case "interest":
        out += layout_interest(val, structure.inner_id);
        break;
      case "editable":
        out += layout_editable(val, structure.inner_id);
        break;
      case "text-editable":
        out += layout_text_editable(val, structure.inner_id);
        break;
      case "modal":
        out += layout_modal(val, structure.inner_id);
        break;
      case "link":
        out += layout_link(val, structure.href, structure.title);
        break;
      case "decimal":
        out += number_format(val, structure.digits);
        break;
      case "general":
      default:
        out += layout_general(val);
        break;
    }
    out += val;
  }
  out += "</td>";
  return out;
}
const _n_general = 2;
const _n_price = 3;
const _n_general_js = "0,0.00";
const _n_plain = 2;
const _n_percent = 5;
const _n_nozero = 4;

function layout_general(val) {
  let out;
  if (typeof val === "number" && !isNaN(val)) {
    out = new Intl.NumberFormat().format(val);
  } else {
    out = val;
  }
  return out;
}

const number_format = (val) => val.replace("undefined", "");

function layout_link(val, href, title) {
  let out = '<a href="' + href + '"';
  out += ' title="' + title + '">';
  out += val + "</a>";
  return out;
}

const layout_modal = (val, id) => {
  let out = '<div><div style="max-width: 90%; float: left;">';
  out += val;
  out += '</div><div style="float: right;">';
  out += '<a data-toggle="modal" href="#' + id + '">';
  out += '<i class="icon-cog"></i></a></div></div>';
  return out;
};
function layout_text_editable(val, id) {
  var out = '<div style="width: 87%; float: left;">';
  out += '<label class="editable" data-type="editable"';
  out += ' data-for="#' + id + '" data-format="' + _n_general_js + '"';
  out += ' id="' + id + '-label"';
  out += ' data-actualvalue="' + _n(val, _n_plain) + '">';
  out += val;
  out += "</label>";
  out += '<input class="table-input" type="text"';
  out += ' id="' + id + '"';
  out += ' value="' + val + '"';
  out += ' onfocus="slctInpVal(this)">';
  out += "</div>";
  out += '<div style="float: right">';
  out += '<a data-toggle="modal" href="#evencalc"><i class="icon-cog"></i></a>';
  out += '<a href="#" id="rmOiRow"><i class="icon-trash"></i></a></div>';
  return out;
}
function layout_editable(val, id) {
  var out = '<label class="editable" data-type="editable"';
  out += ' data-for="#' + id + '" data-format="' + _n_general_js + '"';
  out += ' id="' + id + '-label"';
  out += ' data-actualvalue="' + _n(val, _n_plain) + '">';
  out += _n(val, _n_general);
  out += "</label>";
  out += '<input class="table-input number" type="text" step="any"';
  out += ' id="' + id + '"';
  out += ' value="' + _n(val, _n_plain) + '"';
  out += ' onfocus="slctInpVal(this)">';
  return out;
}

function layout_interest(val, id) {
  return `<div>
            <div style="max-width: 90%; float: left;">
              ${val}
            </div>
            <div style="float: right;">
              <a id="${id}" href="#">
                <i class="icon-repeat"></i>
              </a>
            </div>
          </div>`;
}

function layout_source(val, id) {
  let out = '<span id="' + id + '"';
  out += ' data-format="' + _n_general_js + '"';
  out += ' data-actualvalue="' + _n(val, _n_plain) + '">';
  out += _n(val, _n_general);
  out += "</span>";
  return out;
}
function layout_asmp(val, view, title) {
  let out = '<div style="max-width: 90%; float: left;">';
  out += val;
  out += '</div><div style="float: right;">';
  out += '<a href="/mlp/?view=' + view + "&QuestID=" + "123" + '"';
  out += ' title="' + title + '">';
  out += '<i class="icon-wrench"></i></a></div>';
  return out;
}

function layout_plus(val) {
  let out = '<div style="max-width: 90%; float: left;">';
  out += val;
  out += '</div><div style="float: right;">';
  out += '<a href="#" id="addOiRow"><i class="icon-plus"></i></a></div>';
  return out;
}

function layout_abbr(val, hint) {
  return `<abbr title="${hint}">${val}</abbr>`;
}

function _n(number, format = _n_general) {
  switch (format) {
    case _n_percent:
      return _nf(number, 0) + "%";
    case _n_nozero:
      if (number == 0) {
        return "-";
      } else {
        return _nf(number, 2);
      }
    default:
      return _nf(number, format);
  }
}

function _nf(val, digits) {
  val = parseFloat(val);
  digits = parseInt(digits);
  let text = val
    .toFixed(digits)
    .replace(/\B(?=(\d{3})+(?!\d))/g, sessionStorage.setItem("user_data", "th_sep"));
  text = text.replace(".", sessionStorage.setItem("user_data", "dec_sep"));
  text = text.replace(" ", "&nbsp;");
  return text;
}

/*
  function _num($number, $digits = 0, $return = false) {
  $value = number_format(round((double) $number, (int) $digits), (int) $digits, $_SESSION['user_data']['dec_sep'], $_SESSION['user_data']['th_sep']);
  $value = str_replace(' ', "&nbsp;", $value);
  if ($return == false) {
    echo $value;
  } else
    return $value;
}

function _nf($val, $digits) {
  $val = (double) $val;
  $digits = (int) $digits;
  $text = number_format(round($val, $digits), $digits, $_SESSION['user_data']['dec_sep'], $_SESSION['user_data']['th_sep']);  
  $text = str_replace(' ', "&nbsp;", $text);
  return $text;
}

// number formats
if (!defined('_n_general'))
  define('_n_general', 0);
if (!defined('_n_general_js'))
  define('_n_general_js', '0,0');
if (!defined('_n_price'))
  define('_n_price', 1);
define('_n_plain', 2);
define('_n_percent', 5);
define('_n_nozero', 4);

function _n($number, $format = _n_general) {
  switch ($format) {
    case _n_percent:
      return _nf($number, 0) . '%';
      break;
    case _n_nozero:
      if ($number == 0) {
        return '-';
      } else {
        return _nf($number, 2);
      }
      break;
    default:
      return _nf($number, $format);
      break;
  }
 if ($format == _n_general) {
   return _nf($number, 0);
 } else if ($format == _n_price) {
   return _nf($number, 2);
 } else if ($format == _n_plain) {
   return round($number, 6);
 } else if ($format == _n_percent) {
   return _nf($number, 0) . '%';
 } else if ($format == _n_nozero) {
   if ($number == 0) {
     return '-';
   } else {
     return _nf($number, 2);
   }
 }
}

 // an option to output numbers for reviewing them in the fertilizer, seed, fuel etc. assumption pages
function _num_smart($number, $return = false) {
  $number = (double) $number;
  if ($number == 0) {
    $digits = 0;
  } else if (abs($number) < 10) {
    $digits = 2;
  } else {
    $digits = 0;
  }
  $value = number_format(round($number, $digits), $digits, $_SESSION['user_data']['dec_sep'], $_SESSION['user_data']['th_sep']);
  $value = str_replace(' ', "&nbsp;", $value);
  if ($return == false)
    echo $value;
  else
    return $value;
}

function _num_plain($number, $digits = 0, $return = false) {
  $value = round((double) $number, (int) $digits);
  if ($return == false) {
    echo $value;
  } else
    return $value;

//  if ($return == false) {
//    echo number_format((double) $number, (int) $digits, $_SESSION['user_data']['dec_sep'], '');
//    return;
//  } else {
//    return number_format((double) $number, (int) $digits, $_SESSION['user_data']['dec_sep'], '');
//  }
}



*/
