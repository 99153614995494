import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import "./SelectMenu.css";

export default function SelectMenu({
  options,
  searchable,
  onChange = Function.prototype,
  placeholder,
  singleSelect,
  selecteds,
  singleChoice
}) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");

  const selectOpt = (value) => {
    if (singleChoice) return onChange(value);
    onChange([...selecteds, value]);
  };

  return (
    <div className="select-menu">
      <button
        onClick={() => setVisible(true)}
        className="select-menu-placeholder"
      >
        <span>{placeholder}</span>
        <FiChevronDown />
      </button>
      <div
        onClick={() => setVisible(false)}
        className={`select-menu-shape ${visible && "active"}`}
      />
      <div className={`select-menu-main ${visible && "active"}`}>
        {searchable && (
          <input
            type="search"
            placeholder="Search"
            className="select-menu-search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        <div className="select-menu-options">
          {options
            .filter((opt) =>
              (singleSelect || !singleChoice)
                ? selecteds.findIndex(
                    (subopt) => subopt.value === opt.value
                  ) === -1
                : true
            )
            .filter((opt) =>
              search
                ? (opt.label || opt.value)
                    .trim()
                    .toLowerCase()
                    .includes(search.trim().toLowerCase())
                : true
            )
            .map((opt) => (
              <button
                onClick={() => selectOpt(opt)}
                key={opt.value}
                className="select-menu-btn"
              >
                {opt.label || opt.value}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}
