import {
  APrices,
  Balance,
  Changes,
  Crops,
  Existing,
  General,
  PL,
} from "components/Quest";
import {
  BChickens,
  Bull,
  Chickens,
  Dairy,
  Goats,
  Pig,
  Sheep,
} from "components/Quest/LiveStock";
import { useLoaderContext } from "hooks/useLoaderContext";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { request } from "request";
import { useDebounce } from "use-debounce";

export default function QuestionnairePage() {
  const { id } = useParams();
  const [data, setData] = useState();
  const loader = useLoaderContext();
  const [updatedData, setUpdatedData] = useState({});
  const [newData, setNewData] = useState({});
  const [debouncedData] = useDebounce(newData, 2000);
  const [crops, setCrops] = useState([]);
  const [orchards, setOrchards] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [subequipments, setSubequipments] = useState([]);
  const [liveStockSelects, setLiveStockSelects] = useState({});
  const [balanceData, setBalanceData] = useState({});
  const [adjustPricesData, setAdjustPricesData] = useState({});

  const fetchData = async () => {
    if (id !== "new") {
      loader.start();
      const resData = await request([
        { method: "quests@show", params: { id: +id } },
        { method: "crops@crops" },
        { method: "crops@orchards" },
        { method: "equipment@catList" },
        { method: "equipment@typeList", params: { grouped_by_cat: true } },
        { method: "livestock@bulls" },
        { method: "livestock@cows" },
        { method: "livestock@sheep" },
        { method: "livestock@goats" },
        { method: "quests@balance", params: { id: +id } },
        { method: "quests@materials", params: { id: +id } },
      ]);

      setData(resData[0]?.result);
      setCrops(resData[1]);
      setOrchards(resData[2]);
      setEquipments(resData[3]);
      setSubequipments(resData[4]);
      setUpdatedData(resData[0]?.result);
      setBalanceData(resData[9]?.result);
      setAdjustPricesData(resData[10]?.result);

      setLiveStockSelects({
        bulls: resData[5]?.result,
        cows: resData[6]?.result,
        sheep: resData[7]?.result,
        goats: resData[8]?.result,
      });
      loader.stop();
    } else {
      setData({});
    }
  };

  console.log(data)
  const updateData = async () => {
    loader.start();

    if (data?.data) data.data = data?.data || [];
    if (debouncedData?.data) debouncedData.data = debouncedData?.data || [];
    await request("quests@save", { ...newData, id: data?.id });
    loader.stop();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedData]);

  if (!data) return <h1>Loading...</h1>;

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <Link to={`/cash-flow/${id}`}>Cash 💰</Link>
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                >
                  General info
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                >
                  Crops
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_3"
                >
                  Livestock
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_4"
                >
                  Balance Sheet
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_5"
                >
                  Existing loan
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_6"
                >
                  P&amp;L
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_7"
                >
                  Adjust Prices
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_8"
                >
                  Changes
                </a>
              </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <General data={updatedData} setUpdatedData={setNewData} />
              <Crops
                data={updatedData}
                setUpdatedData={setUpdatedData}
                crops={crops}
                orchards={orchards}
                equipments={equipments}
                subequipments={subequipments}
              />
              {/* LIVE STOCK */}
              <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                  <li className="nav-item">
                    <a
                      className="nav-link active pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_a"
                    >
                      Bull growing
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_b"
                    >
                      Dairy herd
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_c"
                    >
                      Sheep
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_d"
                    >
                      Goats
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_i"
                    >
                      Chickens
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_f"
                    >
                      Broiler chickens
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link pt-0"
                      data-bs-toggle="tab"
                      href="#kt_tab_pane_g"
                    >
                      Pig
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <Bull
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                    selects={liveStockSelects?.bulls}
                  />
                  <Dairy
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                    selects={liveStockSelects?.cows}
                  />
                  <Sheep
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                    selects={liveStockSelects?.sheep}
                  />
                  <Goats
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                    selects={liveStockSelects?.goats}
                  />
                  <Chickens
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                  />
                  <BChickens
                    data={updatedData}
                    setUpdatedData={setUpdatedData}
                  />
                  <Pig data={updatedData} setUpdatedData={setUpdatedData} />
                </div>
              </div>
              {/* /LIVE STOCK */}
              <Balance
                balanceData={balanceData}
                data={updatedData}
                setUpdatedData={setUpdatedData}
              />
              <Existing data={updatedData} setUpdatedData={setNewData} />
              <PL data={updatedData} setUpdatedData={setUpdatedData} />
              <APrices
                adjustPricesData={adjustPricesData}
                data={updatedData}
                setUpdatedData={setUpdatedData}
              />
              <Changes data={updatedData} setUpdatedData={setUpdatedData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
