import React from "react";

export default function CropBudget() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted">Crop budgets</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                >
                  Group by kind
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                >
                  Group by indicator
                </a>
              </li>
            </ul>
            <div className="tab-content mt-10" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <table className="table table-center table-row-bordered align-middle gs-0 gy-3 border border-gray-300">
                  <thead className="fw-semibold fs-6 text-center">
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="min-w-250px pb-4" rowSpan={3}>
                        Name
                      </th>
                      <th className="" colSpan={2}>
                        Total
                      </th>
                      <th className="" colSpan={3}>
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          CARROTS
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 100:500
                            </a>
                          </div>
                        </div>
                      </th>
                      <th className="" colSpan={3}>
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          MAIZE GRAIN
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 55:80
                            </a>
                          </div>
                        </div>
                      </th>
                      <th className="" colSpan={3}>
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          SPRING GHERKINS GREENHOUSE
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 38:300
                            </a>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="" rowSpan={2}>
                        Total, EUR
                      </th>
                      <th className="" rowSpan={2}>
                        Per 1 Ha, EUR
                      </th>
                      <th className="" rowSpan={2}>
                        Total, EUR
                      </th>
                      <th className="" rowSpan={2}>
                        Per 1 Ha, EUR
                      </th>
                      <th className="">Carrot</th>
                      <th className="" rowSpan={2}>
                        Total, EUR
                      </th>
                      <th className="" rowSpan={2}>
                        Per 1 Ha, EUR
                      </th>
                      <th className="">Maize grain</th>
                      <th className="" rowSpan={2}>
                        Total, EUR
                      </th>
                      <th className="" rowSpan={2}>
                        Per 1 Ha, EUR
                      </th>
                      <th className="">Gherkins</th>
                    </tr>
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="">Per 1 MT, EUR</th>
                      <th className="">Per 1 MT, EUR</th>
                      <th className="">Per 1 MT, EUR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border border-gray-300">
                      <td>Area, ha / harvest, MT</td>
                      <td className="text-end" />
                      <td className="text-end">0.0000 Ha</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300 bg-hover-secondary highlight-light">
                      <td>Gross revenue</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300 bg-hover-secondary highlight-light">
                      <td>Direct costs</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Seeds</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Fertilizers</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Plant protection</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Other materials</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Labor cost</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Cost of services</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="highlight-light border border-gray-300 bg-hover-secondary">
                      <td>Indirect income/(expenditure)</td>
                      <td className="text-end  bg-light-danger">0.00</td>
                      <td className="text-end  bg-light-danger">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Selling, General &amp; Administrative</td>
                      <td className="text-end  bg-light-danger">0.00</td>
                      <td className="text-end  bg-light-danger">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Other Operating Income / (Loss)</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Other Income / (Loss)</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>(Increase) / decrease in Trade Debtors</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Increase / (Decrease) in Trade Creditors</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Receipt / (Repayment) of debt</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300 highlight-light">
                      <td>Net cash flow</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <table
                  id="kt_datatable_vertical_scroll"
                  className="table table-center table-row-bordered me-3 gs-7 border border-gray-300 w-auto"
                >
                  <thead className="fw-semibold fs-6 text-center">
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="min-w-250px pb-4" rowSpan={3}>
                        Name
                      </th>
                      <th className="" colSpan={4}>
                        Total, EUR
                      </th>
                      <th className="" colSpan={4}>
                        Per 1 Ha, EUR
                      </th>
                      <th className="" colSpan={3}>
                        Per 1 MT, EUR
                      </th>
                    </tr>
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="" rowSpan={2}>
                        Total
                      </th>
                      <th className="">
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          CARROTS
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 100:500
                            </a>
                          </div>
                        </div>
                      </th>
                      <th className="">
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          MAIZE GRAIN
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 55:80
                            </a>
                          </div>
                        </div>
                      </th>
                      <th className="">
                        <span
                          className="text-primary text-hover-info cursor-pointer"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-start"
                          data-kt-menu-offset=""
                        >
                          SPRING GHERKINS GREENHOUSE
                        </span>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-250px border border-gray-300"
                          data-kt-menu="true"
                        >
                          <div className="menu-item">
                            <a href="#summary" className="menu-link px-3">
                              1 Summary of operations 38:300
                            </a>
                          </div>
                        </div>
                      </th>
                      <th className="" rowSpan={2}>
                        Total
                      </th>
                      <th className="" rowSpan={2}>
                        CARROTS
                      </th>
                      <th className="" rowSpan={2}>
                        MAIZE GRAIN
                      </th>
                      <th className="" rowSpan={2}>
                        SPRING GHERKINS GREENHOUSE
                      </th>
                      <th className="">CARROTS</th>
                      <th className="">MAIZE GRAIN</th>
                      <th className="">SPRING GHERKINS GREENHOUSE</th>
                    </tr>
                    <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                      <th className="">Carrot</th>
                      <th className="">Maize grain</th>
                      <th className="">Gherkins</th>
                      <th className="">Carrot</th>
                      <th className="">Maize grain</th>
                      <th className="">Gherkins</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border border-gray-300">
                      <td>Area, ha / harvest, MT</td>
                      <td className="text-end" />
                      <td className="text-end">0.0000 Ha</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="highlight-light border border-gray-300">
                      <td>Gross revenue</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="highlight-light border border-gray-300">
                      <td>Direct costs</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Seeds</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Fertilizers</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Plant protection</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Other materials</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Labor cost</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Cost of services</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Indirect income/(expenditure)</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="highlight-light border border-gray-300">
                      <td>Selling, General &amp; Administrative</td>
                      <td className="text-end  bg-light-danger">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end  bg-light-danger" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="highlight-light border border-gray-300">
                      <td>Other Operating Income / (Loss)</td>
                      <td className="text-end bg-light-danger">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end bg-light-danger" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Other Income / (Loss)</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>(Increase) / decrease in Trade Debtors</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Increase / (Decrease) in Trade Creditors</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="border border-gray-300">
                      <td>Receipt / (Repayment) of debt</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                    <tr className="highlight-light border border-gray-300">
                      <td>Net cash flow</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end">0.00</td>
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                      <td className="text-end" />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
