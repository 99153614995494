import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  accountPageLinks,
  appsList,
  authPageLinks,
  dashboardsList,
  generalPageLinks,
  helpLinks,
  langs,
  layoutsLinksList,
  moreDashboardsList,
  themesList,
  utilitiesPageLinks,
  widgetsPageLinks,
} from "./contsants";
import Cookies from "js-cookie";

export default function Header() {
  const navigate = useNavigate();

  const logout = () => {
    Cookies.remove("XSRF-TOKEN");
    navigate("/login");
  };

  return (
    <div id="kt_app_header" className="app-header">
      <div
        className="app-container container-xxl d-flex align-items-stretch justify-content-between"
        id="kt_app_header_container"
      >
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
          <Link to="/">
            <img
              alt="Logo"
              src="/assets/media/logos/clara-default.svg"
              className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
            />
            <img
              alt="Logo"
              src="/assets/media/logos/clara-dark.svg"
              className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
            />
          </Link>
        </div>
        <div
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
          id="kt_app_header_wrapper"
        >
          <div
            className="app-header-menu app-header-mobile-drawer align-items-stretch"
            data-kt-drawer="true"
            data-kt-drawer-name="app-header-menu"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="250px"
            data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#kt_app_header_menu_toggle"
            data-kt-swapper="true"
            data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
          >
            <div
              className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
              id="kt_app_header_menu"
              data-kt-menu="true"
            >
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
              >
                <span className="menu-link">
                  <span className="menu-title">Dashboards</span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>

                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px">
                  <div
                    className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible"
                    data-kt-menu-dismiss="true"
                  >
                    <div className="row">
                      <div className="col-lg-8 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                        <div className="row">
                          {dashboardsList.map(({ name, subname, icon }, i) => (
                            <div key={i} className="col-lg-6 mb-3">
                              <div className="menu-item p-0 m-0">
                                <a
                                  href="/metronic8/demo1/../demo1/index.html"
                                  className="menu-link"
                                >
                                  <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                    <span className="svg-icon svg-icon-primary svg-icon-1">
                                      {icon}
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fs-6 fw-bold text-gray-800">
                                      {name}
                                    </span>
                                    <span className="fs-7 fw-semibold text-muted">
                                      {subname}
                                    </span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="separator separator-dashed mx-5 my-5"></div>
                        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
                          <div className="d-flex flex-column me-5">
                            <div className="fs-6 fw-bold text-gray-800">
                              Landing Page Template
                            </div>
                            <div className="fs-7 fw-semibold text-muted">
                              Onpe page landing template with pricing & others
                            </div>
                          </div>
                          <a
                            href="/metronic8/demo1/../demo1/landing.html"
                            className="btn btn-sm btn-primary fw-bold"
                          >
                            Explore
                          </a>
                        </div>
                      </div>

                      <div className="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end">
                        <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                          More Dashboards
                        </h4>
                        {moreDashboardsList.map(({ label, link }, i) => (
                          <div key={i} className="menu-item p-0 m-0">
                            <a href={link} className="menu-link py-2">
                              <span className="menu-title">{label}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-lg-down-accordion me-0 me-lg-2"
              >
                <span className="menu-link">
                  <span className="menu-title">Pages</span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>

                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0">
                  <div className="menu-active-bg px-4 px-lg-0">
                    <div className="d-flex w-100 overflow-auto">
                      <ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1">
                        <li className="nav-item mx-lg-1">
                          <a
                            className="nav-link py-3 py-lg-6 active text-active-primary"
                            href="#general"
                            data-bs-toggle="tab"
                            data-bs-target="#kt_app_header_menu_pages_pages"
                          >
                            General
                          </a>
                        </li>

                        <li className="nav-item mx-lg-1">
                          <a
                            className="nav-link py-3 py-lg-6 text-active-primary"
                            href="#account"
                            data-bs-toggle="tab"
                            data-bs-target="#kt_app_header_menu_pages_account"
                          >
                            Account
                          </a>
                        </li>

                        <li className="nav-item mx-lg-1">
                          <a
                            className="nav-link py-3 py-lg-6 text-active-primary"
                            href="#authentication"
                            data-bs-toggle="tab"
                            data-bs-target="#kt_app_header_menu_pages_authentication"
                          >
                            Authentication
                          </a>
                        </li>

                        <li className="nav-item mx-lg-1">
                          <a
                            className="nav-link py-3 py-lg-6 text-active-primary"
                            href="#utilities"
                            data-bs-toggle="tab"
                            data-bs-target="#kt_app_header_menu_pages_utilities"
                          >
                            Utilities
                          </a>
                        </li>

                        <li className="nav-item mx-lg-1">
                          <a
                            className="nav-link py-3 py-lg-6 text-active-primary"
                            href="#widgets"
                            data-bs-toggle="tab"
                            data-bs-target="#kt_app_header_menu_pages_widgets"
                          >
                            Widgets
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content py-4 py-lg-8 px-lg-7">
                      <div
                        className="tab-pane active w-lg-1000px"
                        id="kt_app_header_menu_pages_pages"
                      >
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="row">
                              {generalPageLinks.map((sublinks, index) => (
                                <div
                                  key={index}
                                  className="col-lg-3 mb-6 mb-lg-0"
                                >
                                  {sublinks.map(
                                    ({ title, links }, subindex) => (
                                      <div
                                        key={subindex}
                                        className={
                                          sublinks.length > 1 &&
                                          sublinks.length - 1 !== subindex
                                            ? "mb-6"
                                            : "mb-0"
                                        }
                                      >
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          {title}
                                        </h4>
                                        {links.map(
                                          ({ label, link }, subindex2) => (
                                            <div
                                              key={subindex2}
                                              className="menu-item p-0 m-0"
                                            >
                                              <a
                                                href={link}
                                                className="menu-link"
                                              >
                                                <span className="menu-title">
                                                  {label}
                                                </span>
                                              </a>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <img
                              src="/assets/media/stock/600x600/img-82.jpg"
                              className="rounded mw-100"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane w-lg-600px"
                        id="kt_app_header_menu_pages_account"
                      >
                        <div className="row">
                          <div className="col-lg-5 mb-6 mb-lg-0">
                            <div className="row">
                              {accountPageLinks.map((sublinks, index) => (
                                <div key={index} className="col-lg-6">
                                  {sublinks.map(({ label, link }, index) => (
                                    <div
                                      key={index}
                                      className="menu-item p-0 m-0"
                                    >
                                      <a href={link} className="menu-link">
                                        <span className="menu-title">
                                          {label}
                                        </span>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-lg-7">
                            <img
                              src="/assets/media/stock/900x600/46.jpg"
                              className="rounded mw-100"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane w-lg-1000px"
                        id="kt_app_header_menu_pages_authentication"
                      >
                        <div className="row">
                          {authPageLinks.map((sublinks, index) => (
                            <div key={index} className="col-lg-3 mb-6 mb-lg-0">
                              {sublinks.map(({ title, links }, subindex) => (
                                <div
                                  key={subindex}
                                  className={
                                    sublinks.length > 1 &&
                                    sublinks.length - 1 !== subindex
                                      ? "mb-6"
                                      : "mb-0"
                                  }
                                >
                                  <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                    {title}
                                  </h4>
                                  {links.map(({ label, link }, subindex2) => (
                                    <div
                                      key={subindex2}
                                      className="menu-item p-0 m-0"
                                    >
                                      <a href={link} className="menu-link">
                                        <span className="menu-title">
                                          {label}
                                        </span>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        className="tab-pane w-lg-1000px"
                        id="kt_app_header_menu_pages_utilities"
                      >
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="row">
                              {utilitiesPageLinks.map((sublinks, index) => (
                                <div
                                  key={index}
                                  className="col-lg-3 mb-6 mb-lg-0"
                                >
                                  {sublinks.map(
                                    ({ title, links }, subindex) => (
                                      <div
                                        key={subindex}
                                        className={
                                          sublinks.length > 1 &&
                                          sublinks.length - 1 !== subindex
                                            ? "mb-6"
                                            : "mb-0"
                                        }
                                      >
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          {title}
                                        </h4>
                                        {links.map(
                                          ({ label, link }, subindex2) => (
                                            <div
                                              key={subindex2}
                                              className="menu-item p-0 m-0"
                                            >
                                              <a
                                                href={link}
                                                className="menu-link"
                                              >
                                                <span className="menu-title">
                                                  {label}
                                                </span>
                                              </a>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="col-lg-5 pe-lg-5">
                            <img
                              src="/assets/media/stock/600x600/img-84.jpg"
                              className="rounded mw-100"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane w-lg-500px"
                        id="kt_app_header_menu_pages_widgets"
                      >
                        <div className="row">
                          <div className="col-lg-4 mb-6 mb-lg-0">
                            {widgetsPageLinks.map(({ label, link }, i) => (
                              <div key={i} className="menu-item p-0 m-0">
                                <a href={link} className="menu-link">
                                  <span className="menu-title">{label}</span>
                                </a>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-8">
                            <img
                              src="/assets/media/stock/900x600/44.jpg"
                              className="rounded mw-100"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
              >
                <span className="menu-link">
                  <span className="menu-title">Apps</span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>

                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px">
                  {appsList.map(({ title, links, link, icon }, index) => (
                    <React.Fragment key={index}>
                      {links?.length ? (
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          <span className="menu-link">
                            <span className="menu-icon">
                              <span className="svg-icon svg-icon-3">
                                {icon}
                              </span>
                            </span>
                            <span className="menu-title">{title}</span>
                            <span className="menu-arrow"></span>
                          </span>

                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {links?.map(
                              ({ label, links: sublinks, link }, subindex) => (
                                <React.Fragment key={subindex}>
                                  {sublinks?.length ? (
                                    <div
                                      data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                                      data-kt-menu-placement="right-start"
                                      className="menu-item menu-lg-down-accordion"
                                    >
                                      <span className="menu-link">
                                        <span className="menu-bullet">
                                          <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">
                                          {label}
                                        </span>
                                        <span className="menu-arrow"></span>
                                      </span>

                                      <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                        {sublinks.map(
                                          ({ label, link }, subindex2) => (
                                            <div
                                              key={subindex2}
                                              className="menu-item"
                                            >
                                              <a
                                                className="menu-link"
                                                href={link}
                                              >
                                                <span className="menu-bullet">
                                                  <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">
                                                  {label}
                                                </span>
                                              </a>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="menu-item">
                                      <a className="menu-link" href={link}>
                                        <span className="menu-bullet">
                                          <span className="bullet bullet-dot"></span>
                                        </span>
                                        <span className="menu-title">
                                          {label}
                                        </span>
                                      </a>
                                    </div>
                                  )}
                                </React.Fragment>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="menu-item">
                          <a className="menu-link" href={link}>
                            <span className="menu-icon">
                              <span className="svg-icon svg-icon-2">
                                {icon}
                              </span>
                            </span>
                            <span className="menu-title">{title}</span>
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item here show"
              >
                <span className="menu-link">
                  <span className="menu-title">Layouts</span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>

                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px">
                  <div
                    className="menu-active-bg pt-1 pb-3 px-3 py-lg-6 px-lg-6"
                    data-kt-menu-dismiss="true"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          {layoutsLinksList.map(({ title, links }, index) => (
                            <div key={index} className="col-lg-6 mb-3">
                              <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                                {title}
                              </h4>

                              {links.map(({ label, link }, subindex) => (
                                <div
                                  key={subindex}
                                  className="menu-item p-0 m-0"
                                >
                                  <a href={link} className="menu-link">
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
                                    </span>
                                    <span className="menu-title">{label}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>

                        <div className="separator separator-dashed mx-lg-5 mt-2 mb-6"></div>

                        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mb-5 mb-lg-0 mx-lg-5">
                          <div className="d-flex flex-column me-5">
                            <div className="fs-6 fw-bold text-gray-800">
                              Layout Builder
                            </div>
                            <div className="fs-7 fw-semibold text-muted">
                              Customize, preview and export
                            </div>
                          </div>
                          <a
                            href="/metronic8/demo1/../demo1/layout-builder.html"
                            className="btn btn-sm btn-primary fw-bold"
                          >
                            Try Builder
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3 py-lg-3 pe-lg-8 d-flex align-items-center">
                        <img
                          src="/assets/media/stock/900x600/45.jpg"
                          className="rounded mw-100"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
              >
                <span className="menu-link">
                  <span className="menu-title">Help</span>
                  <span className="menu-arrow d-lg-none"></span>
                </span>

                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">
                  {helpLinks.map(({ title, link, icon }, index) => (
                    <div key={index} className="menu-item">
                      <a
                        className="menu-link"
                        href={link}
                        target="_blank"
                        title="Check out over 200 in-house components, plugins and ready for use solutions"
                        data-bs-toggle="tooltip"
                        rel="noreferrer"
                        data-bs-trigger="hover"
                        data-bs-dismiss="click"
                        data-bs-placement="right"
                      >
                        <span className="menu-icon">
                          <span className="svg-icon svg-icon-3">{icon}</span>
                        </span>
                        <span className="menu-title">{title}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="app-navbar flex-shrink-0">
            <div className="app-navbar-item ms-1 ms-md-3">
              <a
                href="#bottom"
                className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <span className="svg-icon theme-light-show svg-icon-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9905 5.62598C10.7293 5.62574 9.49646 5.9995 8.44775 6.69997C7.39903 7.40045 6.58159 8.39619 6.09881 9.56126C5.61603 10.7263 5.48958 12.0084 5.73547 13.2453C5.98135 14.4823 6.58852 15.6185 7.48019 16.5104C8.37186 17.4022 9.50798 18.0096 10.7449 18.2557C11.9818 18.5019 13.2639 18.3757 14.429 17.8931C15.5942 17.4106 16.5901 16.5933 17.2908 15.5448C17.9915 14.4962 18.3655 13.2634 18.3655 12.0023C18.3637 10.3119 17.6916 8.69129 16.4964 7.49593C15.3013 6.30056 13.6808 5.62806 11.9905 5.62598Z"
                      fill="currentColor"
                    />
                    <path
                      d="M22.1258 10.8771H20.627C20.3286 10.8771 20.0424 10.9956 19.8314 11.2066C19.6204 11.4176 19.5018 11.7038 19.5018 12.0023C19.5018 12.3007 19.6204 12.5869 19.8314 12.7979C20.0424 13.0089 20.3286 13.1274 20.627 13.1274H22.1258C22.4242 13.1274 22.7104 13.0089 22.9214 12.7979C23.1324 12.5869 23.2509 12.3007 23.2509 12.0023C23.2509 11.7038 23.1324 11.4176 22.9214 11.2066C22.7104 10.9956 22.4242 10.8771 22.1258 10.8771Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.9905 19.4995C11.6923 19.5 11.4064 19.6187 11.1956 19.8296C10.9848 20.0405 10.8663 20.3265 10.866 20.6247V22.1249C10.866 22.4231 10.9845 22.7091 11.1953 22.9199C11.4062 23.1308 11.6922 23.2492 11.9904 23.2492C12.2886 23.2492 12.5746 23.1308 12.7854 22.9199C12.9963 22.7091 13.1147 22.4231 13.1147 22.1249V20.6247C13.1145 20.3265 12.996 20.0406 12.7853 19.8296C12.5745 19.6187 12.2887 19.5 11.9905 19.4995Z"
                      fill="currentColor"
                    />
                    <path
                      d="M4.49743 12.0023C4.49718 11.704 4.37865 11.4181 4.16785 11.2072C3.95705 10.9962 3.67119 10.8775 3.37298 10.8771H1.87445C1.57603 10.8771 1.28984 10.9956 1.07883 11.2066C0.867812 11.4176 0.749266 11.7038 0.749266 12.0023C0.749266 12.3007 0.867812 12.5869 1.07883 12.7979C1.28984 13.0089 1.57603 13.1274 1.87445 13.1274H3.37299C3.6712 13.127 3.95706 13.0083 4.16785 12.7973C4.37865 12.5864 4.49718 12.3005 4.49743 12.0023Z"
                      fill="currentColor"
                    />
                    <path
                      d="M11.9905 4.50058C12.2887 4.50012 12.5745 4.38141 12.7853 4.17048C12.9961 3.95954 13.1147 3.67361 13.1149 3.3754V1.87521C13.1149 1.57701 12.9965 1.29103 12.7856 1.08017C12.5748 0.869313 12.2888 0.750854 11.9906 0.750854C11.6924 0.750854 11.4064 0.869313 11.1955 1.08017C10.9847 1.29103 10.8662 1.57701 10.8662 1.87521V3.3754C10.8664 3.67359 10.9849 3.95952 11.1957 4.17046C11.4065 4.3814 11.6923 4.50012 11.9905 4.50058Z"
                      fill="currentColor"
                    />
                    <path
                      d="M18.8857 6.6972L19.9465 5.63642C20.0512 5.53209 20.1343 5.40813 20.1911 5.27163C20.2479 5.13513 20.2772 4.98877 20.2774 4.84093C20.2775 4.69309 20.2485 4.54667 20.192 4.41006C20.1355 4.27344 20.0526 4.14932 19.948 4.04478C19.8435 3.94024 19.7194 3.85734 19.5828 3.80083C19.4462 3.74432 19.2997 3.71531 19.1519 3.71545C19.0041 3.7156 18.8577 3.7449 18.7212 3.80167C18.5847 3.85845 18.4607 3.94159 18.3564 4.04633L17.2956 5.10714C17.1909 5.21147 17.1077 5.33543 17.0509 5.47194C16.9942 5.60844 16.9649 5.7548 16.9647 5.90264C16.9646 6.05048 16.9936 6.19689 17.0501 6.33351C17.1066 6.47012 17.1895 6.59425 17.294 6.69878C17.3986 6.80332 17.5227 6.88621 17.6593 6.94272C17.7959 6.99923 17.9424 7.02824 18.0902 7.02809C18.238 7.02795 18.3844 6.99865 18.5209 6.94187C18.6574 6.88509 18.7814 6.80195 18.8857 6.6972Z"
                      fill="currentColor"
                    />
                    <path
                      d="M18.8855 17.3073C18.7812 17.2026 18.6572 17.1195 18.5207 17.0627C18.3843 17.006 18.2379 16.9767 18.0901 16.9766C17.9423 16.9764 17.7959 17.0055 17.6593 17.062C17.5227 17.1185 17.3986 17.2014 17.2941 17.3059C17.1895 17.4104 17.1067 17.5345 17.0501 17.6711C16.9936 17.8077 16.9646 17.9541 16.9648 18.1019C16.9649 18.2497 16.9942 18.3961 17.0509 18.5326C17.1077 18.6691 17.1908 18.793 17.2955 18.8974L18.3563 19.9582C18.4606 20.0629 18.5846 20.146 18.721 20.2027C18.8575 20.2595 19.0039 20.2887 19.1517 20.2889C19.2995 20.289 19.4459 20.26 19.5825 20.2035C19.7191 20.147 19.8432 20.0641 19.9477 19.9595C20.0523 19.855 20.1351 19.7309 20.1916 19.5943C20.2482 19.4577 20.2772 19.3113 20.277 19.1635C20.2769 19.0157 20.2476 18.8694 20.1909 18.7329C20.1341 18.5964 20.051 18.4724 19.9463 18.3681L18.8855 17.3073Z"
                      fill="currentColor"
                    />
                    <path
                      d="M5.09528 17.3072L4.0345 18.368C3.92972 18.4723 3.84655 18.5963 3.78974 18.7328C3.73294 18.8693 3.70362 19.0156 3.70346 19.1635C3.7033 19.3114 3.7323 19.4578 3.78881 19.5944C3.84532 19.7311 3.92822 19.8552 4.03277 19.9598C4.13732 20.0643 4.26147 20.1472 4.3981 20.2037C4.53473 20.2602 4.68117 20.2892 4.82902 20.2891C4.97688 20.2889 5.12325 20.2596 5.25976 20.2028C5.39627 20.146 5.52024 20.0628 5.62456 19.958L6.68536 18.8973C6.79007 18.7929 6.87318 18.6689 6.92993 18.5325C6.98667 18.396 7.01595 18.2496 7.01608 18.1018C7.01621 17.954 6.98719 17.8076 6.93068 17.671C6.87417 17.5344 6.79129 17.4103 6.68676 17.3058C6.58224 17.2012 6.45813 17.1183 6.32153 17.0618C6.18494 17.0053 6.03855 16.9763 5.89073 16.9764C5.74291 16.9766 5.59657 17.0058 5.46007 17.0626C5.32358 17.1193 5.19962 17.2024 5.09528 17.3072Z"
                      fill="currentColor"
                    />
                    <path
                      d="M5.09541 6.69715C5.19979 6.8017 5.32374 6.88466 5.4602 6.94128C5.59665 6.9979 5.74292 7.02708 5.89065 7.02714C6.03839 7.0272 6.18469 6.99815 6.32119 6.94164C6.45769 6.88514 6.58171 6.80228 6.68618 6.69782C6.79064 6.59336 6.87349 6.46933 6.93 6.33283C6.9865 6.19633 7.01556 6.05003 7.01549 5.9023C7.01543 5.75457 6.98625 5.60829 6.92963 5.47184C6.87301 5.33539 6.79005 5.21143 6.6855 5.10706L5.6247 4.04626C5.5204 3.94137 5.39643 3.8581 5.25989 3.80121C5.12335 3.74432 4.97692 3.71493 4.82901 3.71472C4.68109 3.71452 4.53458 3.7435 4.39789 3.80001C4.26119 3.85652 4.13699 3.93945 4.03239 4.04404C3.9278 4.14864 3.84487 4.27284 3.78836 4.40954C3.73185 4.54624 3.70287 4.69274 3.70308 4.84066C3.70329 4.98858 3.73268 5.135 3.78957 5.27154C3.84646 5.40808 3.92974 5.53205 4.03462 5.63635L5.09541 6.69715Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>

                <span className="svg-icon theme-dark-show svg-icon-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z"
                      fill="currentColor"
                    />
                    <path
                      d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z"
                      fill="currentColor"
                    />
                    <path
                      d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>

              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                data-kt-menu="true"
                data-kt-element="theme-mode-menu"
              >
                {themesList.map(({ label, icon }, index) => (
                  <div key={index} className="menu-item px-3 my-0">
                    <a
                      href={`#${label}`}
                      className="menu-link px-3 py-2"
                      data-kt-element="mode"
                      data-kt-value={label.toLowerCase()}
                    >
                      <span className="menu-icon" data-kt-element="icon">
                        <span className="svg-icon svg-icon-3">{icon}</span>
                      </span>
                      <span className="menu-title">{label}</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="app-navbar-item ms-1 ms-md-3"
              id="kt_header_user_menu_toggle"
            >
              <div
                className="cursor-pointer symbol symbol-30px symbol-md-40px"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <img src="/assets/media/avatars/300-1.jpg" alt="user" />
              </div>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                      <img alt="Logo" src="/assets/media/avatars/300-1.jpg" />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="fw-bold d-flex align-items-center fs-5">
                        Max Smith
                        <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                          Pro
                        </span>
                      </div>
                      <a
                        href="#max@kt.com"
                        className="fw-semibold text-muted text-hover-primary fs-7"
                      >
                        max@kt.com
                      </a>
                    </div>
                  </div>
                </div>

                <div className="separator my-2"></div>

                <div className="menu-item px-5">
                  <a
                    href="/metronic8/demo1/../demo1/account/overview.html"
                    className="menu-link px-5"
                  >
                    My Profile
                  </a>
                </div>

                <div className="menu-item px-5">
                  <a
                    href="/metronic8/demo1/../demo1/apps/projects/list.html"
                    className="menu-link px-5"
                  >
                    <span className="menu-text">My Projects</span>
                    <span className="menu-badge">
                      <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                        3
                      </span>
                    </span>
                  </a>
                </div>

                <div
                  className="menu-item px-5"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="left-start"
                  data-kt-menu-offset="-15px, 0"
                >
                  <a href="#my-subscriptions" className="menu-link px-5">
                    <span className="menu-title">My Subscription</span>
                    <span className="menu-arrow"></span>
                  </a>
                  <div className="menu-sub menu-sub-dropdown w-175px py-4">
                    <div className="menu-item px-3">
                      <a
                        href="/metronic8/demo1/../demo1/account/referrals.html"
                        className="menu-link px-5"
                      >
                        Referrals
                      </a>
                    </div>

                    <div className="menu-item px-3">
                      <a
                        href="/metronic8/demo1/../demo1/account/billing.html"
                        className="menu-link px-5"
                      >
                        Billing
                      </a>
                    </div>

                    <div className="menu-item px-3">
                      <a
                        href="/metronic8/demo1/../demo1/account/statements.html"
                        className="menu-link px-5"
                      >
                        Payments
                      </a>
                    </div>

                    <div className="menu-item px-3">
                      <a
                        href="/metronic8/demo1/../demo1/account/statements.html"
                        className="menu-link d-flex flex-stack px-5"
                      >
                        Statements
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          title="View your statements"
                        ></i>
                      </a>
                    </div>

                    <div className="separator my-2"></div>

                    <div className="menu-item px-3">
                      <div className="menu-content px-3">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input w-30px h-20px"
                            type="checkbox"
                            value="1"
                            defaultChecked
                            name="notifications"
                          />
                          <span className="form-check-label text-muted fs-7">
                            Notifications
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-item px-5">
                  <a
                    href="/metronic8/demo1/../demo1/account/statements.html"
                    className="menu-link px-5"
                  >
                    My Statements
                  </a>
                </div>

                <div className="separator my-2"></div>

                <div
                  className="menu-item px-5"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="left-start"
                  data-kt-menu-offset="-15px, 0"
                >
                  <a href="#english" className="menu-link px-5">
                    <span className="menu-title position-relative">
                      Language
                      <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                        English
                        <img
                          className="w-15px h-15px rounded-1 ms-2"
                          src="/assets/media/flags/united-states.svg"
                          alt=""
                        />
                      </span>
                    </span>
                  </a>
                  <div className="menu-sub menu-sub-dropdown w-175px py-4">
                    {langs.map(({ lang, flag }, i) => (
                      <div key={flag} className="menu-item px-3">
                        <a
                          href="/"
                          className={`menu-link d-flex px-5 ${
                            i === 0 && "active"
                          }`}
                        >
                          <span className="symbol symbol-20px me-4">
                            <img className="rounded-1" src={flag} alt={lang} />
                          </span>
                          {lang}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="menu-item px-5 my-1">
                  <a
                    href="/metronic8/demo1/../demo1/account/settings.html"
                    className="menu-link px-5"
                  >
                    Account Settings
                  </a>
                </div>

                <div className="menu-item px-5">
                  <button
                    onClick={logout}
                    className="remove-btn menu-link px-5"
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
            <div
              className="app-navbar-item d-lg-none ms-2 me-n3"
              title="Show header menu"
            >
              <div
                className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-35px h-md-35px"
                id="kt_app_header_menu_toggle"
              >
                <span className="svg-icon svg-icon-2 svg-icon-md-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
