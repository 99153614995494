/* eslint-disable react-hooks/exhaustive-deps */
import { useLoaderContext } from "hooks/useLoaderContext";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { request } from "request";
import { useDebounce } from "use-debounce";

export default function Client() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [regions, setRegions] = useState([]);
  const loader = useLoaderContext();
  const [updatedData, setUpdatedData] = useState({});
  const [debouncedData] = useDebounce(updatedData, 2000);

  const fetchData = async () => {
    loader.start();
    const resData = await request("companies@show", { id: +id });
    setData(resData);
    setUpdatedData(resData);

    const regionsData = await request("regions@list");
    setRegions(regionsData);
    loader.stop();
  };

  const updateData = async () => {
    loader.start();
    data.data = data.data || [];
    debouncedData.data = debouncedData.data || [];
    await request("companies@save", { ...data, ...debouncedData });
    loader.stop();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateData();
  }, [debouncedData]);

  


  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="card m-5">
          <div className="card-body p-5">
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                >
                  General info
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                >
                  Projections
                </a>
              </li>
            </ul>
            <div className="tab-content mt-10" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <div className="mt-10 w-50">
                  <h2 className="text-gray-800">Company name</h2>
                  <div className="mt-10">
                    <div className="mb-5 d-flex flex-row align-items-center justify-content-between">
                      <label htmlFor="input1" className="form-label">
                        Albanian
                      </label>
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Name"
                        onChange={(e) =>
                          setUpdatedData((prev) => ({
                            ...prev,
                            name: { ...prev.name, md: e.target.value },
                          }))
                        }
                        defaultValue={data?.name?.md}
                      />
                    </div>
                    <div className="mb-5 d-flex flex-row align-items-center justify-content-between">
                      <label htmlFor="input2" className="form-label">
                        English
                      </label>
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Name"
                        onChange={(e) =>
                          setUpdatedData((prev) => ({
                            ...prev,
                            name: { ...prev.name, en: e.target.value },
                          }))
                        }
                        defaultValue={data?.name?.en}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10 w-50">
                  <h2 className="text-gray-800">Client account number</h2>
                  <div className="mt-10">
                    <div className="mb-5 d-flex flex-row align-items-center justify-content-between">
                      <label htmlFor="input1" className="form-label">
                        Client account number
                      </label>
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Name"
                        onChange={(e) =>
                          setUpdatedData((prev) => ({
                            ...prev,
                            code: e.target.value,
                          }))
                        }
                        defaultValue={data?.code}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10 w-50">
                  <h2 className="text-gray-800">Location</h2>
                  <div className="mt-10">
                    <div className="mb-5 d-flex align-items-center justify-content-between">
                      <label htmlFor="input1" className="form-label">
                        Region
                      </label>
                      <div className="d-block w-50">
                        <select
                          onChange={(e) =>
                            setUpdatedData((prev) => ({
                              ...prev,
                              region_id: e.target.value,
                            }))
                          }
                          className="form-select form-select-md"
                          data-control="select2"
                          data-placeholder="Select an option"
                        >
                          {regions?.map((region) => (
                            <option
                              key={region.r_id}
                              value={region.r_id}
                              selected={region.r_id === data?.region?.district?.r_id}
                            >
                              {region.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mb-5 d-flex align-items-center justify-content-between">
                      <label htmlFor="input2" className="form-label">
                        Municipality
                      </label>
                      <div className="d-block w-50">
                        <select
                          onChange={(e) =>
                            setUpdatedData((prev) => ({
                              ...prev,
                              region_id: e.target.value,
                            }))
                          }
                          className="form-select form-select-md"
                          data-control="select2"
                          data-placeholder="Select an option"
                        >
                          {regions.find(region => region.r_id === data?.region?.district?.r_id)?.children?.map(subRegion => (
                            <option key={subRegion.r_id} value={subRegion.r_id} selected={subRegion.r_id === data?.region?.province?.r_id}>{subRegion.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-10 w-50">
                  <h2 className="text-gray-800">Family farm</h2>
                  <div className="mt-10">
                    <div className="mb-5 d-flex flex-row align-items-center">
                      <label
                        htmlFor="flexCheckDefault"
                        className="form-label m-0"
                      >
                        Family farm
                      </label>
                      <input
                        className="form-check-input ms-5"
                        type="checkbox"
                        defaultChecked={data?.family}
                        onChange={(e) =>
                          setUpdatedData((prev) => ({
                            ...prev,
                            family: e.target.checked,
                          }))
                        }
                        id="flexCheckDefault"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="my-10" style={{ display: "flex", gap: "3px" }}>
                  <Link to="/questionnaire/new" className="btn btn-sm btn-primary">
                    <i className="bi bi-plus-lg" />
                    New
                  </Link>
                  <button
                    className="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                  >
                    <i className="bi bi-arrow-up-circle" />
                    Import
                  </button>
                  {/* Modal Start */}
                  <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h3 className="modal-title">Import questionnaire</h3>
                          {/*begin::Close*/}
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span className="svg-icon svg-icon-1" />
                          </div>
                          {/*end::Close*/}
                        </div>
                        <div className="modal-body d-flex justify-content-between">
                          <input
                            type="file"
                            className="form-control me-5"
                            id="formFile"
                          />
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                          >
                            Preview
                          </button>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Modal end */}
                </div>
                <table
                  id="kt_datatable_zero_configuration"
                  className="table table-row-bordered gy-5 border border-gray-300"
                >
                  <thead className="border border-gray-300">
                    <tr className="fw-semibold fs-6 text-muted">
                      <th>#</th>
                      <th>Status</th>
                      <th>Purpose of loan</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="border border-gray-300">
                    {data?.quests?.map((quest, idx) => (
                      <tr key={quest.id}>
                        <td>{idx + 1}</td>
                        <td>
                          {
                            {
                              open: (
                                <span className="badge badge-warning">
                                  In process
                                </span>
                              ),
                              approved: (
                                <span className="badge badge-success">
                                  Approved
                                </span>
                              )
                            }[quest.status?.toLowerCase()]
                          }
                        </td>
                        <td>{quest.loan_purpose}</td>
                        <td>{moment(quest.start_date).format("DD.MM.YYYY")}</td>
                        <td>
                          <div>
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary rotate"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-start"
                              data-kt-menu-offset="0px, 0px"
                            >
                              Action
                              <span className="svg-icon svg-icon-3 rotate-180 ms-3 me-0">
                                <i className="bi bi-caret-down-fill" />
                              </span>
                            </button>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                              data-kt-menu="true"
                            >
                              <div className="menu-item px-3">
                                <a href="#edit" className="menu-link px-3">
                                  Edit
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#build" className="menu-link px-3">
                                  Build MLP
                                </a>
                              </div>
                              <div
                                className="menu-item px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_assign_status"
                              >
                                <span className="menu-link px-3">
                                  Assign status
                                </span>
                              </div>
                              <div
                                className="menu-item px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_copy_quest"
                              >
                                <span className="menu-link px-3">
                                  Copy questionnaire
                                </span>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#download" className="menu-link px-3">
                                  Download data
                                </a>
                              </div>
                              <div className="separator mb-1 opacity-75" />
                              <div
                                className="menu-item px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_delete_data"
                              >
                                <span className="menu-link px-3">Delete</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Modal Assign status start */}
                <div
                  className="modal fade"
                  tabIndex={-1}
                  id="kt_modal_assign_status"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title">Assign status</h3>
                        {/*begin::Close*/}
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span className="svg-icon svg-icon-1" />
                        </div>
                        {/*end::Close*/}
                      </div>
                      <div className="modal-body">
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#kt_tab_new_ticked"
                            >
                              New ticket
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#kt_tab_tickeds"
                            >
                              Tickets
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="kt_tab_new_ticked"
                            role="tabpanel"
                          >
                            <p>
                              Once you select the status, the data cannot be
                              changed
                            </p>
                            <form action="">
                              <div className="my-3">
                                <label htmlFor="textarea">Comment text</label>
                                <textarea
                                  className="form-control"
                                  id="textarea"
                                  defaultValue={""}
                                />
                              </div>
                              <div className="my-3">
                                <div className="form-check form-check-custom form-check-success form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="Success"
                                    name="status"
                                    id="flexCheckboxLg"
                                  />
                                  <label
                                    className="form-check-label badge badge-success p-2"
                                    htmlFor="flexCheckboxLg"
                                  >
                                    Success
                                  </label>
                                </div>
                                <div className="form-check form-check-custom form-check-danger form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="Danger"
                                    name="status"
                                    id="flexCheckboxSm"
                                  />
                                  <label
                                    className="form-check-label badge badge-danger p-2 my-2"
                                    htmlFor="flexCheckboxSm"
                                  >
                                    Danger
                                  </label>
                                </div>
                                <div className="form-check form-check-custom form-check-warning form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    defaultValue="Warning"
                                    name="status"
                                    id="flexRadioLg"
                                  />
                                  <label
                                    className="form-check-label badge badge-warning p-2"
                                    htmlFor="flexRadioLg"
                                  >
                                    Warning
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="kt_tab_tickeds"
                            role="tabpanel"
                          >
                            <div className="table-responsive">
                              <table className="table table-striped table-row-bordered gy-5 gs-7 border border-gray-500">
                                <thead className="text-center">
                                  <tr className="fw-bold fs-6 text-gray-800 border border-gray-500">
                                    <th className="border border-gray-500">
                                      Name
                                    </th>
                                    <th className="border border-gray-500">
                                      Position
                                    </th>
                                    <th className="border border-gray-500">
                                      Office
                                    </th>
                                  </tr>
                                </thead>
                                <tbody />
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-light"
                          data-bs-dismiss="modal"
                        >
                          Canel
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal Assign status end */}
                {/* Modal Copy questionnaire start */}
                <div
                  className="modal fade"
                  tabIndex={-1}
                  id="kt_modal_copy_quest"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title">Copy questionnaire</h3>
                        {/*begin::Close*/}
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span className="svg-icon svg-icon-1" />
                        </div>
                        {/*end::Close*/}
                      </div>
                      <div className="modal-body">
                        <div className="my-3">
                          <h3>Including:</h3>
                        </div>
                        <div className="my-5 mt-10">
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              id="Balance"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Balance"
                            >
                              Balance information
                            </label>
                          </div>
                          <div className="form-check form-check-custom form-check-solid my-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              id="Assumptions"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Assumptions"
                            >
                              Assumptions
                            </label>
                          </div>
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              id="Archived"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Archived"
                            >
                              Archived prices
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-light"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal Copy questionnaire start */}
                {/* Modal Delete Data start */}
                <div
                  className="modal fade"
                  tabIndex={-1}
                  id="kt_modal_delete_data"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h3 className="modal-title">Delete questionnaire</h3>
                        {/*begin::Close*/}
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span className="svg-icon svg-icon-1" />
                        </div>
                        {/*end::Close*/}
                      </div>
                      <div className="modal-body">
                        <p>Are you sure to delete this questionnaire?</p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-light"
                          data-bs-dismiss="modal"
                        >
                          Canel
                        </button>
                        <button type="button" className="btn btn-danger">
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Modal  Delete Data start */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
