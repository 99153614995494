import React from "react";

export default function LaborCosts() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted mb-2">
            Summary of labor costs
          </p>
          <p className="text-muted">Drip irrigation</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="table-responsive">
              <table className="table table-row-bordered border border-gray-300 table-row-gray-300 align-middle gs-0 gy-3">
                <thead className="border border-gray-300">
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Category</th>
                    <th className="min-w-150px">Units</th>
                    <th className="min-w-150px">Total workload</th>
                    <th className="min-w-150px">Rate, EUR</th>
                    <th className="min-w-150px">Gross wage, EUR</th>
                  </tr>
                </thead>
                <tbody className="border border-gray-300">
                  <tr>
                    <td>Workers</td>
                    <td className="text-end">shift</td>
                    <td className="text-end">1.00</td>
                    <td className="text-end">20.00</td>
                    <td className="text-end">20.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
