import React from "react";

export default function Costs() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Sour Cherry</h1>
          <p className="breadcrumb-item text-muted mb-2">
            Summary of material costs
          </p>
          <p className="text-muted">Summary of material costs</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <table className="table table-row-bordered gy-5 border border-gray-300">
              <thead className="border border-gray-300">
                <tr className="fw-bold text-muted">
                  <th className="min-w-150px">Type</th>
                  <th className="min-w-150px">Material</th>
                  <th className="min-w-150px">Volume per kg (l)/ha</th>
                  <th className="min-w-150px">Area, ha</th>
                  <th className="min-w-150px">Total volume, kg (l)</th>
                  <th className="min-w-150px">Price, EUR/kg (l)</th>
                  <th className="min-w-150px">Total cost, EUR</th>
                </tr>
              </thead>
              <tbody className="border border-gray-300">
                <tr>
                  <td>Fungicides</td>
                  <td>Zato plus, kg</td>
                  <td>2.00</td>
                  <td>1.00</td>
                  <td>2.00</td>
                  <td>44.000</td>
                  <td>88.00</td>
                </tr>
                <tr>
                  <td>insecticideS</td>
                  <td>Karathe zeon, kg</td>
                  <td>1.00</td>
                  <td>1.00</td>
                  <td>1.00</td>
                  <td>64.000</td>
                  <td>64.00</td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <b>Total</b>
                  </td>
                  <td>
                    <b>152.00</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
