import axios from "axios";
import { Footer, Header, LinksWidget } from "components";
import { BACKEND_URL } from "constants";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Routes, { routes } from "Routes";

export default function App() {
  const location = useLocation();
  const isPublicPage = routes
    .filter(({ isPublic }) => isPublic)
    .map(({ path }) => path)
    .includes(location.pathname);
  const [loading, setLoading] = useState(true);

  const fetchCookies = async () => {
    try {
      await axios.get(`${BACKEND_URL}/sanctum/csrf-cookie`);
    } catch (ex) {
      console.error(ex);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = "/assets/js/scripts.bundle.js";
      script.id = "main-script";

      const mainScript = document.querySelector("#main-script");
      if (mainScript) mainScript.replaceWith(script);
      else document.body.insertAdjacentElement("beforeend", script);
    }, 1000);

    fetchCookies();
  }, []);

  if (loading) return "Loading...";

  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            {!isPublicPage && <Header />}
            <Routes />
            {!isPublicPage && <Footer />}
          </div>
        </div>
      </div>
      <LinksWidget />
    </div>
  );
}
