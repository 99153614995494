import React, { useState } from "react";
import { TbHandClick } from "react-icons/tb";
import { MdOutlineClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { routes } from "Routes";

export default function LinksWidget() {
  const [showLinks, setShowLinks] = useState(false);
  const location = useLocation();

  return (
    <div className="links-widget">
      <div
        onClick={() => setShowLinks(false)}
        className={`links-widget-shape ${showLinks && "active"}`}
      />
      <div className={`links ${showLinks && "active"}`}>
        {routes.map(({ path, link }) => (
          <Link key={link || path} to={link || path} style={location.pathname === (link || path) ? { opacity: "0.1", cursor: "not-allowed" } : {}}>{link || path}</Link>
        ))}
      </div>
      <button
        onClick={() => setShowLinks((prev) => !prev)}
        className={`btn ${showLinks && "active"}`}
      >
        {showLinks ? <MdOutlineClose /> : <TbHandClick />}
      </button>
    </div>
  );
}
