import React, { useCallback, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Bull({ data, setUpdatedData, selects: allSelects }) {
  const [selectIdx, setSelectIdx] = useState(1);
  const getSelects = useCallback(
    (key) => allSelects?.[key]?.[selectIdx],
    [allSelects, selectIdx]
  );
  const { control, register } = useForm({
    defaultValues: {
      herds: data?.bulls?.herds,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "herds",
  });

  return (
    <div className="tab-content" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="kt_tab_pane_a"
        role="tabpanel"
      >
        <div className="row">
          <div className="col-6">
            <h3 className="mx-3 mb-4">Herd as of starting date</h3>
            <table>
              <thead>
                <tr className="text-end">
                  <th>Quantity</th>
                  <th>Weight, kg</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, idx) => (
                  <tr key={field.id} className="text-end">
                    <td className="pl-0">
                      <input
                        type="number"
                        min={0}
                        pattern="[0-9]{10}"
                        className="form-control form-control-sm input-width input-width"
                        {...register(`herds.${idx}.quantity`)}
                      />
                    </td>
                    <td className="d-flex align-items-center gap-3">
                      <select
                        className="form-select form-select-sm"
                        aria-label="Default select example"
                        {...register(`herds.${idx}.kg`)}
                      >
                        {getSelects("buyWeights")?.map((val) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        className="p-0 border-0 bg-transparent"
                        onClick={() => remove(idx)}
                        id="rmBulls"
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={append}
              className="btn btn-success mt-3 btn-sm mx-2"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
          <div className="col-6">
            <h3 className="m-0">Monthly weight gain norm</h3>
            <div className="form-group mt-4 w-50 mb-10">
              <span className="d-block mb-2">Monthly weight gain norm</span>
              <select
                className="form-select form-select-sm"
                data-control="select2"
                data-placeholder="Select an option"
                onChange={(e) => setSelectIdx(e.target.value)}
              >
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
              </select>
            </div>
            <h3>Sale settings</h3>
            <span>Sell when age reaches</span>
            <div className="form-group">
              <div className="row align-items-center mt-3">
                <div className="col-3">
                  <select
                    className="form-select form-select-sm"
                    value={data?.bulls?.feedperiod?.val}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        bulls: {
                          ...(prev?.bulls || {}),
                          feedperiod: {
                            ...(prev?.bulls?.feedperiod || {}),
                            val: +e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {getSelects("ages")?.map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 d-flex gap-4 mt-4">
                  <label htmlFor="">months</label>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      aria-label="Checkbox for following text input"
                      checked={data?.bulls?.feedperiod?.inuse}
                      onChange={(e) =>
                        setUpdatedData((prev) => ({
                          ...prev,
                          bulls: {
                            ...(prev?.bulls || {}),
                            feedperiod: {
                              ...(prev?.bulls?.feedperiod || {}),
                              inuse: e.target.checked ? 1 : 0,
                            },
                          },
                        }))
                      }
                    />
                    In use
                  </label>
                </div>
                <label htmlFor="" className="mt-3">
                  Sell when weight reaches
                </label>
                <div className="col-3 mt-3">
                  <select
                    className="form-select form-select-sm"
                    value={data?.bulls?.sellweight?.val}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        bulls: {
                          ...(prev?.bulls || {}),
                          sellweight: {
                            ...(prev?.bulls?.sellweight || {}),
                            val: +e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {getSelects("sellWeights")?.map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 d-flex gap-4 mt-4">
                  <label htmlFor="">Kg</label>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      aria-label="Checkbox for following text input"
                      checked={data?.bulls?.sellweight?.inuse}
                      onChange={(e) =>
                        setUpdatedData((prev) => ({
                          ...prev,
                          bulls: {
                            ...(prev?.bulls || {}),
                            sellweight: {
                              ...(prev?.bulls?.sellweight || {}),
                              inuse: e.target.checked ? 1 : 0,
                            },
                          },
                        }))
                      }
                    />
                    In use
                  </label>
                </div>
              </div>
              <div className="row mt-10 align-items-center">
                <h3>Purchase settings</h3>
                <label htmlFor="">Age</label>
                <div className="col-3 mt-3">
                  <select
                    className="form-select form-select-sm"
                    value={data?.bulls?.buyage?.val}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        bulls: {
                          ...(prev?.bulls || {}),
                          buyage: {
                            ...(prev?.bulls?.buyage || {}),
                            val: +e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {getSelects("ages")?.map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 d-flex gap-4 mt-4">
                  <label htmlFor="">Kg</label>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      aria-label="Checkbox for following text input"
                      checked={data?.bulls?.buyage?.inuse}
                      onChange={(e) =>
                        setUpdatedData((prev) => ({
                          ...prev,
                          bulls: {
                            ...prev.bulls,
                            buyage: {
                              ...(prev.bulls?.buyage || {}),
                              inuse: e.target.checked ? 1 : 0,
                            },
                          },
                        }))
                      }
                    />
                    In use
                  </label>
                </div>
                <label htmlFor="" className="mt-3">
                  Weight
                </label>
                <div className="col-3 mt-3">
                  <select
                    className="form-select form-select-sm"
                    value={data?.bulls?.buyweight?.val}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        bulls: {
                          ...(prev?.bulls || {}),
                          buyweight: {
                            ...(prev?.bulls?.buyweight || {}),
                            val: +e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {getSelects("buyWeights")?.map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6 d-flex gap-4 mt-4">
                  <label htmlFor="">Kg</label>
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      aria-label="Checkbox for following text input"
                      checked={data?.bulls?.buyweight?.inuse}
                      onChange={(e) =>
                        setUpdatedData((prev) => ({
                          ...prev,
                          bulls: {
                            ...(prev?.bulls || {}),
                            buyweight: {
                              ...(prev?.bulls?.buyweight || {}),
                              inuse: e.target.checked ? 1 : 0,
                            },
                          },
                        }))
                      }
                    />
                    In use
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
