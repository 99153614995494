import React from "react";

export default function EquipmentNeeds() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Farmer444</h1>
          <p className="breadcrumb-item text-muted">Equipment needs</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="table-responsive">
              <table className="table align-middle gs-0 gy-3 border border-gray-300">
                <thead className="border border-gray-300">
                  <tr className="fw-bold text-muted">
                    <th className="min-w-250px">Name</th>
                    <th className="min-w-100px">Max need</th>
                    <th>Available</th>
                    <th>Jan.23</th>
                    <th>Feb.23</th>
                    <th>Mar.23</th>
                    <th>Apr.23</th>
                    <th>May.23</th>
                    <th>Jun.23</th>
                    <th>Jul.23</th>
                    <th>Aug.23</th>
                    <th>Sep.23</th>
                    <th>Oct.23</th>
                    <th>Nov.23</th>
                    <th>Dec.23</th>
                    <th>Jan.24</th>
                    <th>Feb.24</th>
                    <th>Mar.24</th>
                    <th>Apr.24</th>
                    <th>May.24</th>
                    <th>Jun.24</th>
                    <th>Jul.24</th>
                    <th>Aug.24</th>
                    <th>Sep.24</th>
                    <th>Oct.24</th>
                    <th>Nov.24</th>
                    <th>Dec.24</th>
                  </tr>
                </thead>
                <tbody className="border border-gray-300">
                  <tr>
                    <td>Tractor trailer up to 6</td>
                    <td className="text-end">2</td>
                    <td className="text-end bg-light-danger">0</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">2</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">2</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                  </tr>
                  <tr>
                    <td>tractors from 40 to 49 hp</td>
                    <td className="text-end">2</td>
                    <td className="text-end bg-light-danger">0</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">2</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">2</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                  </tr>
                  <tr>
                    <td>tractors from 29 to 39 hp</td>
                    <td className="text-end">1</td>
                    <td className="text-end bg-light-danger">0</td>
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end" />
                    <td className="text-end">1</td>
                    <td className="text-end" />
                    <td className="text-end" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
