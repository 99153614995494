import React from "react";

export default function CostOfServicesSummary() {
  return (
    <div className="container-xxl">
      <div className="m-5">
        <h1 className="text-dark fw-bold">Sour Cherry</h1>
        <p className="breadcrumb-item text-muted">
          Summary of cost of services
        </p>
      </div>
      <div className="card m-5">
        <div className="card-body p-5">
          <div className="row">
            <div className="col-lg-12">
              <table
                id="kt_datatable_dom_positioning"
                className="table table-striped table-row-bordered gy-5 gs-7 border border-gray-300"
              >
                <thead>
                  <tr className="border fw-bold border-gray-300">
                    <th>Crop</th>
                    <th>SOUR CHERRY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border border-gray-300">
                    <td>Operation</td>
                    <td>Application of plant protection agents (orchards)</td>
                  </tr>
                  <tr className="border border-gray-300">
                    <td>Units</td>
                    <td>Ha</td>
                  </tr>
                  <tr className="border border-gray-300">
                    <td>Total workload</td>
                    <td>1.00</td>
                  </tr>
                  <tr className="border border-gray-300">
                    <td>Rate, EUR</td>
                    <td>30.00</td>
                  </tr>
                  <tr className="border border-gray-300">
                    <td>Gross wage, EUR</td>
                    <td>30.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
