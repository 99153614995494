import axios from "axios";
import { BACKEND_URL } from "constants";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { getToken } from "utils/functions";

export default function PasswordRecovery() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    setLoading(true);

    try {
      const resData = await axios.post(
        `${BACKEND_URL}/forgot-password`,
        data,
        {
          headers: {
            "X-XSRF-TOKEN": getToken(),
          },
        }
      );

      alert("Recovery message sent to your email!");
    } catch (ex) {
      console.error(ex);
    }

    setLoading(false);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid flex-lg-row">
      <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div className="d-flex flex-center flex-lg-start flex-column position-relative"></div>
      </div>
      <div className="d-flex flex-center w-lg-50 p-10">
        <div className="card rounded-3 w-md-550px">
          <div className="card-body p-10 p-lg-20">
            <form
              className="form w-100"
              noValidate="novalidate"
              id="kt_password_reset_form"
              data-kt-redirect-url="/../demo1/authentication/layouts/creative/new-password.html"
              onSubmit={handleSubmit(submit)}
            >
              <div className="text-center mb-10">
                <a href="#logo" className="mb-7">
                  <img
                    alt="Logo"
                    src="/assets/media/logos/clara-default.svg"
                    className="theme-light-show h-35px"
                  />
                  <img
                    alt="Lo    go"
                    src="/assets/media/logos/clara-dark.svg"
                    className="theme-dark-show h-35px"
                  />
                </a>
                <h2 className="text-gray-600 fw-normal my-5">
                  Enter your email to reset your password.
                </h2>
              </div>
              <div className="fv-row mb-8">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  className="form-control bg-transparent"
                  style={errors.email ? { borderColor: "red" } : {}}
                  {...register("email", {
                    required: "please enter your email",
                  })}
                />
                {errors.email && (
                  <span style={{ color: "red" }}>{errors.email?.message}</span>
                )}
              </div>
              <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                <button
                  id="kt_password_reset_submit"
                  className="btn btn-primary me-4"
                  disabled={loading}
                >
                  {!loading ? (
                    <span className="indicator-label">Sign In</span>
                  ) : (
                    <span className="indicator-label">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  )}
                </button>
                <Link to="/login" className="btn btn-light">
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
