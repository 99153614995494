import React from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Chickens({ data, setUpdatedData }) {
  const { register, control } = useForm({
    defaultValues: {
      hen: data?.goats?.hen,
    },
  });
  const {
    fields: hens,
    append: appendHen,
    remove: removeHen,
  } = useFieldArray({
    name: "hen",
    control,
  });

  return (
    <div className="tab-pane fade" id="kt_tab_pane_i" role="tabpanel">
      <div className="row">
        <div className="col-6">
          <div>
            <h3>Herd as of starting date</h3>
            <table>
              <thead>
                <tr className="text-end">
                  <th>Quantity</th>
                  <th>Weight, kg</th>
                </tr>
              </thead>
              <tbody>
                {hens?.map((field, idx) => (
                  <tr key={field.id} className="text-end">
                    <td>
                      <input
                        type="number"
                        min={0}
                        pattern="[0-9]{10}"
                        className="form-control form-control-sm input-width input-width"
                        {...register(`hen.${idx}.count`)}
                      />
                    </td>
                    <td>
                      <select
                        className="form-select form-select-sm "
                        aria-label="Default select example"
                        {...register(`hen.${idx}.kg`)}
                      >
                        <option value={55}>55</option>
                        <option value={80}>80</option>
                        <option value={110}>110</option>
                        <option value={142}>142</option>
                        <option value={177}>177</option>
                        <option value={214}>214</option>
                        <option value={254}>254</option>
                        <option value={299}>299</option>
                        <option value={344}>344</option>
                        <option value={391}>391</option>
                        <option value={438}>438</option>
                        <option value={488}>488</option>
                      </select>
                    </td>
                    <td>
                      <button
                        className="p-0 border-0 bg-transparent"
                        onClick={() => removeHen(idx)}
                        id="rmBulls"
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={appendHen}
              className="btn btn-success mt-3 d-block btn-sm"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
        </div>
        <div className="col-6">
          <h3>Sale settings</h3>
          <div className="row align-items-center mt-3">
            <label htmlFor="">Sell when age reaches</label>
            <div className="col-3 mt-3">
              <input
                type="number"
                min={0}
                pattern="[0-9]{10}"
                placeholder={0}
                className="form-control form-control-sm input-width  input-width"
                value={data?.broilers?.feedperiod?.val}
                onChange={(e) =>
                  setUpdatedData((prev) => ({
                    ...prev,
                    chickens: {
                      ...(prev?.chickens || {}),
                      feedperiod: {
                        ...(prev?.chickens?.feedperiod || {}),
                        val: +e.target.value,
                      },
                    },
                  }))
                }
              />
            </div>
            <div className="col-3 d-flex gap-4">
              <label htmlFor="">months</label>
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  aria-label="Checkbox for following text input"
                  checked={data?.broilers?.feedperiod?.inuse}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      chickens: {
                        ...(prev?.chickens || {}),
                        feedperiod: {
                          ...(prev?.chickens?.feedperiod || {}),
                          inuse: +e.target.value,
                        },
                      },
                    }))
                  }
                />
                In use
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="row mt-10 align-items-center">
              <label htmlFor="">Sell when weight reaches</label>
              <div className="col-3 mt-3">
                <input
                  type="number"
                  min={0}
                  pattern="[0-9]{10}"
                  placeholder={0}
                  className="form-control form-control-sm input-width input-width"
                  value={data?.broilers?.sellweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      chickens: {
                        ...(prev?.chickens || {}),
                        sellweight: {
                          ...(prev?.chickens?.sellweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.broilers?.sellweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        chickens: {
                          ...(prev?.chickens || {}),
                          sellweight: {
                            ...(prev?.chickens?.sellweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <h3>Purchase settings</h3>
              <label>Age</label>
              <div className="col-3 mt-3">
                <input
                  type="number"
                  min={0}
                  pattern="[0-9]{10}"
                  placeholder={0}
                  className="form-control form-control-sm input-width"
                  value={data?.broilers?.buyage?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      chickens: {
                        ...(prev?.chickens || {}),
                        buyage: {
                          ...(prev?.chickens?.buyage || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">months</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.broilers?.buyage?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        chickens: {
                          ...(prev?.chickens || {}),
                          buyage: {
                            ...(prev?.chickens?.buyage || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <label>Weight</label>
              <div className="col-3 mt-3">
                <input
                  type="number"
                  min={0}
                  pattern="[0-9]{10}"
                  placeholder={0}
                  className="form-control form-control-sm input-width  input-width"
                  value={data?.broilers?.buyweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      chickens: {
                        ...(prev?.chickens || {}),
                        buyweight: {
                          ...(prev?.chickens?.buyweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                />
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.broilers?.buyweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        chickens: {
                          ...(prev?.chickens || {}),
                          buyweight: {
                            ...(prev?.chickens?.buyweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
