import React from "react";
import { renderTableNumbers } from "utils/functions";

export default function AnimalBudget() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Farmer444</h1>
          <p className="breadcrumb-item text-muted">Animal husbandry budgets</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_1"
                >
                  Group by kind
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_2"
                >
                  Group by indicator
                </a>
              </li>
            </ul>
            <div className="tab-content mt-10" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <div className="table-responsive">
                  <table className="table table-hover table-center table-row-bordered me-3 gs-7 border border-gray-300 w-auto">
                    <thead>
                    
                      <tr className="fw-bold border border-gray-300">
                        <th className="min-w-250px" rowSpan={3}>
                          Name
                        </th>
                        <th className="min-w-50px" rowSpan={3}>
                          Total, EUR
                        </th>
                        <th className="" colSpan={11}>
                          Bull growing
                        </th>
                        <th colSpan={11}>Dairy herd</th>
                        <th colSpan={5}>Chickens</th>
                        <th colSpan={3}>Broiler chickens</th>
                      </tr>
                      <tr className="fw-bold border border-gray-300">
                        <th className="min-w-50px" rowSpan={2}>
                          Total, EUR
                        </th>
                        <th colSpan={2}>Per Head</th>
                        <th colSpan={2}>Meat, kg</th>
                        <th colSpan={2}>Meat byproducts, kg</th>
                        <th colSpan={2}>Hides, pcs</th>
                        <th colSpan={2}>Manure, kg</th>
                        <th rowSpan={2}>Total, EUR</th>
                        <th colSpan={2}>Milk, kg</th>
                        <th colSpan={2}>Meat, kg</th>
                        <th colSpan={2}>Meat byproducts, kg</th>
                        <th colSpan={2}>Hides, pcs</th>
                        <th colSpan={2}>Manure, kg</th>
                        <th rowSpan={2}>Total, EUR</th>
                        <th colSpan={2}>Eggs, pcs</th>
                        <th colSpan={2}>Laying hens up to 2 years</th>
                        <th rowSpan={2}>Total, EUR</th>
                        <th colSpan={2}>Meat, kg</th>
                      </tr>
                      <tr className="fw-bold border border-gray-300">
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                        <th className="min-w-50px">Total, EUR</th>
                        <th className="min-w-50px">Per unit, EUR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border border-gray-300">
                        <td>Total</td>
                        {renderTableNumbers(totalData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Gross revenue</td>
                        {renderTableNumbers(grossRevenueData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Direct costs</td>
                        {renderTableNumbers(directCostsData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Feed</td>
                        {renderTableNumbers(feedData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Labor cost</td>
                        {renderTableNumbers(lobarCostData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Herd maintenance</td>
                        {renderTableNumbers(herdMaintenanceData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Purchase of animals</td>
                        {renderTableNumbers(animalsPurchaseData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Net Income</td>
                        {renderTableNumbers(incomeNetData)}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                <div className="table-responsive">
                  <table className="table table-hover table-center table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 border border-gray-300">
                    <thead>
                      <tr className="fw-bold border border-gray-300">
                        <th className="min-w-250px pb-0" rowSpan={3}>
                          Name
                        </th>
                        <th className="min-w-100px" colSpan={17}>
                          Total, EUR
                        </th>
                        <th colSpan={12}>Per unit, EUR</th>
                        <th rowSpan={2} colSpan={2} />
                      </tr>
                      <tr className="fw-bold border border-gray-300">
                        <th className="min-w-100px" rowSpan={2}>
                          Total
                        </th>
                        <th colSpan={5}>Bull growing</th>
                        <th colSpan={6}>Dairy herd</th>
                        <th colSpan={3}>Chickens</th>
                        <th colSpan={2}>Broiler chickens</th>
                        <th colSpan={4}>Bull growing</th>
                        <th colSpan={5}>Dairy herd</th>
                        <th colSpan={2}>Chickens</th>
                        <th>Broiler chickens</th>
                      </tr>
                      <tr className="fw-bold border border-gray-300">
                        <th>Total</th>
                        <th>Per Head</th>
                        <th>Meat, kg</th>
                        <th>Meat byproducts, kg</th>
                        <th>Hides, pcs</th>
                        <th>Manure, kg</th>
                        <th>Total</th>
                        <th>Milk, kg</th>
                        <th>Meat, kg</th>
                        <th>Meat byproducts, kg</th>
                        <th>Hides, pcs</th>
                        <th>Manure, kg</th>
                        <th>Total</th>
                        <th>Eggs, pcs</th>
                        <th>Laying hens up to 2 years</th>
                        <th>Total</th>
                        <th>Meat, kg</th>
                        <th>Per Head</th>
                        <th>Meat, kg</th>
                        <th>Meat byproducts, kg</th>
                        <th>Hides, pcs</th>
                        <th>Manure, kg</th>
                        <th>Milk, kg</th>
                        <th>Meat, kg</th>
                        <th>Meat byproducts, kg</th>
                        <th>Meat byproducts, kg</th>
                        <th>Manure, kg</th>
                        <th>Eggs, pcs</th>
                        <th>Laying hens up to 2 years</th>
                        <th>Meat, kg</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border border-gray-300">
                        <td>Total</td>
                        {renderTableNumbers(totalData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Gross revenue</td>
                        {renderTableNumbers(grossRevenueData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Direct costs</td>
                        {renderTableNumbers(directCostsData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Feed</td>
                        {renderTableNumbers(feedData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Labor cost</td>
                        {renderTableNumbers(lobarCostData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Herd maintenance</td>
                        {renderTableNumbers(herdMaintenanceData)}
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Purchase of animals</td>
                        {renderTableNumbers(animalsPurchaseData)}
                      </tr>
                      <tr className="border border-gray-300 highlight-light">
                        <td>Net Income</td>
                        {renderTableNumbers(incomeNetData)}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const totalData = [
  2, { value: 0, state: "danger" }, 0, 1, 1, 1, 1, 1, 1, 2, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];

const grossRevenueData = [
  2, 0, 0, 1, 1, 1, 1, 1, 1, 2, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];

const directCostsData = [
  1, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];
const feedData = [
  1, { value: 0, state: "danger" }, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];
const lobarCostData = [
  1, { value: 0, state: "danger" }, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];
const herdMaintenanceData = [
  1, { value: 0, state: "danger" }, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];
const animalsPurchaseData = [
  1, { value: 0, state: "danger" }, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];
const incomeNetData = [
  1, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 0, 1, 1,
  2, 0, 1, 2, 2
];


/*
  [
                        [
                          { label: "Name", className: "min-w-250px", rowSpan: 3 },
                          { label: "Total, EUR", className: "min-w-250px", rowSpan: 3 },
                          { label: "Bull growing", rowSpan: 11 },
                          { label: "Dairy herd", rowSpan: 11 },
                          { label: "Chickens", rowSpan: 5 },
                          { label: "Broiler chickens", rowSpan: 3 },
                        ],
                        [
                          { label: "Total, EUR", className: "min-w-50px", rowSpan: 2 },
                          { label: "Per Head", rowSpan: 2 },
                          { label: "Meat, kg", rowSpan: 2 },
                          { label: "Meat byproducts, kg", rowSpan: 2 },
                          { label: "Hides, pcs", rowSpan: 2 },
                          { label: "Manure, kg", rowSpan: 2 },
                          { label: "Milk, kg", rowSpan: 2 },
                        ]
                      ]

*/