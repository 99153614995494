import React from "react";

export default function APrices({ data, adjustPricesData }) {

  return (
    <div className="tab-pane fade" id="kt_tab_pane_7" role="tabpanel">
      <div className="row justify-content-between">
        <div className="col-6">
          <h3>Produced commodity</h3>
          {Object.values(adjustPricesData?.produced || {})?.map((item, index) => (
            <div
              key={item.materialid}
              className={`big-box ${index !== 0 && "mt-5"}`}
            >
              <h4>{item.name}</h4>
              <div className="row align-items-center">
                <div className="col-2">
                  <span>Price</span>
                </div>
                <div className="col-6">
                  <div className="input-group w-auto">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-check text-black"></i>
                      &nbsp; {item.defPrice}
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-sm "
                      defaultValue={item.price}
                    />
                    <span className="input-group-text" id="basic-addon2">
                      MDL/kg
                    </span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-2">
                  <span>Inventory</span>
                </div>
                <div className="col-6 d-flex align-items-center gap-3">
                  <input
                    type="text"
                    className="form-control form-control-sm input-width"
                    placeholder="0"
                    defaultValue={item.reserve}
                  />
                  <span>kg</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-6">
          <h3>Used materials</h3>
          {Object.values(adjustPricesData?.used || {})?.map((item, index) => (
            <div
              key={item.materialid}
              className={`big-box ${index !== 0 && "mt-5"}`}
            >
              <h4>{item.name}</h4>
              <div className="row align-items-center">
                <div className="col-2">
                  <span>Price</span>
                </div>
                <div className="col-6">
                  <div className="input-group w-auto">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa-solid fa-check text-black"></i>
                      &nbsp; {item.defPrice}
                    </span>
                    <input
                      type="text"
                      className="form-control form-control-sm "
                      defaultValue={item.price}
                    />
                    <span className="input-group-text" id="basic-addon2">
                      MDL/kg
                    </span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mt-5">
                <div className="col-2">
                  <span>Inventory</span>
                </div>
                <div className="col-6 d-flex align-items-center gap-3">
                  <input
                    type="text"
                    className="form-control form-control-sm input-width"
                    placeholder="0"
                    defaultValue={item.reserve}
                  />
                  <span>kg</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
