import { EditableItem } from "components";
import React from "react";

export default function SalesSettings() {
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="m-5">
          <h1 className="text-dark fw-bold">Farmer444</h1>
          <p className="breadcrumb-item text-muted">Sales settings</p>
        </div>
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="my-10">
              <div className="my-10">
                <button
                  className="btn btn-sm btn-secondary"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item"
                >
                  Show all
                </button>
                <button className="btn btn-sm btn-secondary">Hide all</button>
              </div>
            </div>
            {/*begin::Accordion*/}
            <div
              className="accordion accordion-icon-toggle"
              id="kt_accordion_2"
            >
              {/*begin::Item*/}
              <div className="mb-5">
                {/*begin::Header*/}
                <div
                  className="accordion-header py-3 d-flex"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item_1"
                >
                  <span className="accordion-icon">
                    <i className="bi bi-caret-right-fill" />
                  </span>
                  <h3 className="fs-4 fw-semibold mb-0 ms-4 text-gray-800">
                    Wheat (WINTER WHEAT)
                  </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div
                  id="kt_accordion_2_item_1"
                  className="fs-6 collapse show ps-10"
                  data-bs-parent="#kt_accordion_2"
                >
                  <table
                    id="kt_datatable_vertical_scroll"
                    className="table table-striped table-row-bordered me-3  gs-7 border border-gray-300 w-auto"
                  >
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                        <td className="w-50">Opening balance, MT:</td>
                        <td className="w-50 bg-light-warning text-end">
                          <EditableItem>0</EditableItem>
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div className="scroll">
                    <table
                      id="kt_datatable_vertical_scroll"
                      className="table table-row-bordered me-3 gs-7 border border-gray-300 w-auto"
                    >
                      <thead className="fw-semibold fs-6 text-center">
                        <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                          <th className="min-w-250px" />
                          <th className="min-w-100px">Total</th>
                          <th>Jan.23</th>
                          <th>Feb.23</th>
                          <th>Mar.23</th>
                          <th>Apr.23</th>
                          <th>May.23</th>
                          <th>Jun.23</th>
                          <th>Jul.23</th>
                          <th>Aug.23</th>
                          <th>Sep.23</th>
                          <th>Oct.23</th>
                          <th>Nov.23</th>
                          <th>Dec.23</th>
                          <th>Jan.24</th>
                          <th>Feb.24</th>
                          <th>Mar.24</th>
                          <th>Apr.24</th>
                          <th>May.24</th>
                          <th>Jun.24</th>
                          <th>Jul.24</th>
                          <th>Aug.24</th>
                          <th>Sep.24</th>
                          <th>Oct.24</th>
                          <th>Nov.24</th>
                          <th>Dec.24</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border border-gray-300">
                          <td>Crop harvest, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Animal feeding, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Harvest sold, MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Suggested price, EUR/MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Corrected price, EUR/MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Revenue, EUR</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Item*/}
              {/*begin::Item*/}
              <div className="mb-5">
                {/*begin::Header*/}
                <div
                  className="accordion-header py-3 d-flex collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item_2"
                >
                  <span className="accordion-icon">
                    <i className="bi bi-caret-right-fill" />
                  </span>
                  <h3 className="fs-4 fw-semibold mb-0 ms-4 text-gray-800">
                    Wheat straw (WINTER WHEAT)
                  </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div
                  id="kt_accordion_2_item_2"
                  className="collapse fs-6 ps-10"
                  data-bs-parent="#kt_accordion_2"
                >
                  <table
                    id="kt_datatable_vertical_scroll"
                    className="table table-row-bordered me-3  gs-7 border border-gray-300 w-auto"
                  >
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                        <td className="w-50">Opening balance, MT:</td>
                        <td className="w-50 bg-light-warning text-end">
                          <EditableItem>0</EditableItem>
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div className="scroll">
                    <table
                      id="kt_datatable_vertical_scroll"
                      className="table table-row-bordered me-3 gs-7 border border-gray-300 w-auto"
                    >
                      <thead className="fw-semibold fs-6 text-center">
                        <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                          <th className="min-w-250px" />
                          <th className="min-w-100px">Total</th>
                          <th>Jan.23</th>
                          <th>Feb.23</th>
                          <th>Mar.23</th>
                          <th>Apr.23</th>
                          <th>May.23</th>
                          <th>Jun.23</th>
                          <th>Jul.23</th>
                          <th>Aug.23</th>
                          <th>Sep.23</th>
                          <th>Oct.23</th>
                          <th>Nov.23</th>
                          <th>Dec.23</th>
                          <th>Jan.24</th>
                          <th>Feb.24</th>
                          <th>Mar.24</th>
                          <th>Apr.24</th>
                          <th>May.24</th>
                          <th>Jun.24</th>
                          <th>Jul.24</th>
                          <th>Aug.24</th>
                          <th>Sep.24</th>
                          <th>Oct.24</th>
                          <th>Nov.24</th>
                          <th>Dec.24</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border border-gray-300">
                          <td>Crop harvest, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Animal feeding, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Harvest sold, MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Suggested price, EUR/MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Corrected price, EUR/MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Revenue, EUR</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Item*/}
              {/*begin::Item*/}
              <div className="mb-5">
                {/*begin::Header*/}
                <div
                  className="accordion-header py-3 d-flex collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item_3"
                >
                  <span className="accordion-icon">
                    <i className="bi bi-caret-right-fill" />
                  </span>
                  <h3 className="fs-4 fw-semibold mb-0 ms-4 text-gray-800">
                    Maize sillage (MAIZE SILAGE)
                  </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div
                  id="kt_accordion_2_item_3"
                  className="collapse fs-6 ps-10"
                  data-bs-parent="#kt_accordion_2"
                >
                  <table
                    id="kt_datatable_vertical_scroll"
                    className="table table-row-bordered me-3  gs-7 border border-gray-300 w-auto"
                  >
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                        <td className="w-50">Opening balance, MT:</td>
                        <td className="w-50 bg-light-warning text-end">
                          <EditableItem>0</EditableItem>
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div className="scroll">
                    <table
                      id="kt_datatable_vertical_scroll"
                      className="table table-row-bordered me-3 gs-7 border border-gray-300 w-auto"
                    >
                      <thead className="fw-semibold fs-6 text-center">
                        <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                          <th className="min-w-250px" />
                          <th className="min-w-100px">Total</th>
                          <th>Jan.23</th>
                          <th>Feb.23</th>
                          <th>Mar.23</th>
                          <th>Apr.23</th>
                          <th>May.23</th>
                          <th>Jun.23</th>
                          <th>Jul.23</th>
                          <th>Aug.23</th>
                          <th>Sep.23</th>
                          <th>Oct.23</th>
                          <th>Nov.23</th>
                          <th>Dec.23</th>
                          <th>Jan.24</th>
                          <th>Feb.24</th>
                          <th>Mar.24</th>
                          <th>Apr.24</th>
                          <th>May.24</th>
                          <th>Jun.24</th>
                          <th>Jul.24</th>
                          <th>Aug.24</th>
                          <th>Sep.24</th>
                          <th>Oct.24</th>
                          <th>Nov.24</th>
                          <th>Dec.24</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border border-gray-300">
                          <td>Crop harvest, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Animal feeding, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Harvest sold, MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Suggested price, EUR/MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Corrected price, EUR/MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Revenue, EUR</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Item*/}
              {/*begin::Item*/}
              <div className="mb-5">
                {/*begin::Header*/}
                <div
                  className="accordion-header py-3 d-flex collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_accordion_2_item_4"
                >
                  <span className="accordion-icon">
                    <i className="bi bi-caret-right-fill" />
                  </span>
                  <h3 className="fs-4 fw-semibold mb-0 ms-4 text-gray-800">
                    Strawberry (STRAWBERRY)
                  </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div
                  id="kt_accordion_2_item_4"
                  className="collapse fs-6 ps-10"
                  data-bs-parent="#kt_accordion_2"
                >
                  <table
                    id="kt_datatable_vertical_scroll"
                    className="table table-row-bordered me-3  gs-7 border border-gray-300 w-auto"
                  >
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                        <td className="w-50">Opening balance, MT:</td>
                        <td className="w-50 bg-light-warning text-end">
                          <EditableItem>0</EditableItem>
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div className="scroll">
                    <table
                      id="kt_datatable_vertical_scroll"
                      className="table table-row-bordered me-3 gs-7 border border-gray-300 w-auto"
                    >
                      <thead className="fw-semibold fs-6 text-center">
                        <tr className="fw-semibold fs-6 text-gray-800 border border-gray-300">
                          <th className="min-w-250px" />
                          <th className="min-w-100px">Total</th>
                          <th>Jan.23</th>
                          <th>Feb.23</th>
                          <th>Mar.23</th>
                          <th>Apr.23</th>
                          <th>May.23</th>
                          <th>Jun.23</th>
                          <th>Jul.23</th>
                          <th>Aug.23</th>
                          <th>Sep.23</th>
                          <th>Oct.23</th>
                          <th>Nov.23</th>
                          <th>Dec.23</th>
                          <th>Jan.24</th>
                          <th>Feb.24</th>
                          <th>Mar.24</th>
                          <th>Apr.24</th>
                          <th>May.24</th>
                          <th>Jun.24</th>
                          <th>Jul.24</th>
                          <th>Aug.24</th>
                          <th>Sep.24</th>
                          <th>Oct.24</th>
                          <th>Nov.24</th>
                          <th>Dec.24</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border border-gray-300">
                          <td>Crop harvest, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Animal feeding, MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Harvest sold, MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border highlight-light border-gray-300">
                          <td>Suggested price, EUR/MT</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Corrected price, EUR/MT</td>
                          <td className="text-end">0</td>
                          {[...new Array(24)].map((_, i) => (
                            <td key={i} className="bg-light-warning text-end">
                              <EditableItem width="100px">0</EditableItem>
                            </td>
                          ))}
                        </tr>
                        <tr className="border border-gray-300">
                          <td>Revenue, EUR</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                          <td className="text-end">0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Item*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
