/* eslint-disable react-hooks/exhaustive-deps */
import { useLoaderContext } from "hooks/useLoaderContext";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { request } from "request";
import { useDebounce } from "use-debounce";
// import { Pagination } from "subcomponents";

export default function Home() {
  const [result, setResult] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const paginationCount = useMemo(
    () => Math.ceil((result?.pagination?.total || 0) / pageCount),
    [result, pageCount]
  );
  const loader = useLoaderContext();
  const [searchText, setSearchText] = useState("");
  const [searchQuery] = useDebounce(searchText, 1000);
  const navigate = useNavigate();

  const fetchData = async () => {
    loader.start();
    try {
      const resData = await request("quests@index", {
        sort_col: "loan_amount",
        per_page: pageCount,
        page: pageNum,
        search: searchQuery,
      });
      if ([401, 419].includes(resData?.response?.status)) {
        return navigate("/login");
      }

      setResult(resData?.result);
    } catch (ex) {
      console.error(ex);
    }

    loader.stop();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageNum, pageCount, searchQuery]);

  useEffect(() => {
    setPageNum(1);
  }, [pageCount]);

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="container-xxl">
        <div className="card m-5">
          <div className="card-body p-5">
            <div className="d-flex flex-column flex-sm-row justify-content-end align-items-center mb-2">
              <a
                href="#asdasd"
                className="btn btn-light-success btn-sm ms-1 mb-1"
              >
                <i className="fa-solid fa-file-excel" /> Export to Excel
              </a>
              <input
                className="form-control form-control-sm form-control-solid w-250px ms-1 mb-1"
                placeholder="Pick date rage"
                id="kt_daterangepicker_1"
              />
              <div className="d-flex align-items-center position-relative ms-1 mb-1">
                {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                <span className="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height={2}
                      rx={1}
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {/*end::Svg Icon*/}
                <input
                  type="text"
                  className="form-control form-control-sm form-control-solid w-250px ps-15"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <a href="#asd" className="btn btn-light-info btn-sm ms-1 mb-1">
                <i className="fa-solid fa-plus" /> New application
              </a>
            </div>
            {/*begin::Table container*/}
            <div className="table-responsive">
              {/*begin::Table*/}
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                {/*begin::Table head*/}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Company name</th>
                    <th className="min-w-140px">Purpose of loan</th>
                    <th className="min-w-120px">Loan amount(th, EUR)</th>
                    <th className="min-w-120px">Date</th>
                    <th className="min-w-120px">Status</th>
                    <th className="min-w-120px">Comments</th>
                    <th className="min-w-120px">Staff</th>
                    <th className="min-w-100px text-center">Actions</th>
                  </tr>
                </thead>
                {/*end::Table head*/}
                {/*begin::Table body*/}
                <tbody>
                  {result?.data?.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={`/client/${item.company.id}`}
                          className="text-hover-dark"
                        >
                          {item.company.name.en}
                        </Link>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/questionnaire/${item.id}`}
                            className="text-hover-dark p-2 flex-grow-1"
                          >
                            {item?.loan_purpose}
                          </Link>
                          <div className="p-2">
                            <img
                              src="/assets/media/png-icons/icon-crop.png"
                              alt="plant"
                              className="icon-png icon-16"
                            />
                          </div>
                          <div className="p-2">
                            <img
                              src="/assets/media/png-icons/icon-bull.png"
                              alt="bull"
                              className="icon-png icon-16"
                            />
                          </div>
                          <div className="p-2">
                            <img
                              src="/assets/media/png-icons/icon-cow.png"
                              alt="cow"
                              className="icon-png icon-16"
                            />
                          </div>
                        </div>
                      </td>
                      <td>{item?.loan_amount}</td>
                      <td>{moment(item?.start_date).format("DD.MM.YYYY")}</td>
                      <td>
                        {
                          {
                            0: (
                              <span className="badge badge-light-warning">
                                Open
                              </span>
                            ),
                            1: (
                              <span className="badge badge-light-success">
                                Approved
                              </span>
                            ),
                            2: (
                              <span className="badge badge-light-danger">
                                Rejected
                              </span>
                            ),
                          }[item?.status]
                        }
                      </td>
                      <td>
                        5
                        <a
                          className="btn btn-icon btn-active-light-primary w-30px h-30px"
                          href="#asd"
                        >
                          {/*begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/communication/com007.svg*/}
                          <span className="svg-icon svg-icon-muted svg-icon-2x">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.3"
                                d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                                fill="currentColor"
                              />
                              <path
                                d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {/*end::Svg Icon*/}
                        </a>
                      </td>
                      <td className="text-dark text-hover-primary fw-bold fs-6">
                        Leyla
                      </td>
                      <td className="text-center">
                        <a
                          className="btn btn-icon btn-active-light-primary py-0 px-1"
                          href="#as"
                        >
                          <i
                            className="fa fa-calculator ms-1 mb-1"
                            aria-hidden="true"
                          />
                        </a>
                        <a
                          className="btn btn-icon btn-active-light-warning py-0 px-1"
                          href="#l"
                        >
                          <i
                            className="fa fa-pencil ms-1 mb-1"
                            aria-hidden="true"
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table container*/}
            <div className="row mb-2">
              <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                <div
                  className="dataTables_length"
                  id="kt_permissions_table_length"
                >
                  <label>
                    <select
                      name="kt_permissions_table_length"
                      aria-controls="kt_permissions_table"
                      className="form-select form-select-sm form-select-solid"
                      onChange={(e) => setPageCount(+e.target.value || 10)}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </label>
                  Showing {pageNum + 1} to {result?.data?.length} of{" "}
                  {result?.pagination?.total} records
                </div>
              </div>
              <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="kt_permissions_table_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        pageNum === 1 ? "disabled" : ""
                      }`}
                      id="kt_permissions_table_previous"
                    >
                      <button
                        className="page-link"
                        onClick={() => setPageNum((prev) => prev - 1)}
                      >
                        <i className="previous" />
                      </button>
                    </li>
                    {[...new Array(paginationCount)].map((_, i) => (
                      <li
                        key={i}
                        className={`paginate_button page-item ${
                          pageNum - 1 === i ? "active" : ""
                        }`}
                      >
                        <button
                          key={i}
                          className="page-link"
                          onClick={() => setPageNum(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        pageNum === paginationCount ? "disabled" : ""
                      }`}
                      id="kt_permissions_table_next"
                    >
                      <button
                        onClick={() => setPageNum((prev) => prev + 1)}
                        className="page-link"
                      >
                        <i className="next" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
