import React from "react";

export default function Existing({ data, setUpdatedData }) {
  return (
    <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
      <table className="table table-row-bordered border border-gray-300">
        <thead className="text-center">
          <tr className="text-end">
            <th>Data at application date</th>
            <th> (EUR/ USD)</th>
            <th>Loan in your Bank</th>
            <th>Loan in other Banks</th>
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          {existingLoanData.map((item, i) => (
            <tr key={i} className="text-end">
              <td style={item.bold ? { fontWeight: 600 } : {}}>
                {item?.value || item}
              </td>
              {data?.extra_data?.loan_details?.[i]?.map((value, subI) => (
                <td key={subI}>
                  <input
                    type="number"
                    min={0}
                    pattern="[0-9]{10}"
                    className="form-control form-control-sm input-width "
                    placeholder={0}
                    defaultValue={value}
                    onChange={(e) =>
                      setUpdatedData((prev) => {
                        const updatedData = {
                          extra_data: {
                            ...data.extra_data,
                          },
                        };
                        console.log(updatedData, "datadatadatadata")

                        updatedData.extra_data.loan_details[i][subI] = +e.target.value;
                        return updatedData;
                      })
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const existingLoanData = [
  "Agro Working Capital loan",
  "Agro Investment loan",
  "Agroland Loan",
  { value: "Total Sum", bold: true },
  "Cash available",
  "Loan payments (in the future)",
];
