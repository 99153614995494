import { BACKEND_URL } from "constants";
import { getToken } from "utils/functions";
import JsonRpcClient from "utils/jsonrpcclient";

const sendRequest = async (method, params) => {
  const csrfToken = getToken();
  const api = new JsonRpcClient({
    endpoint: `${BACKEND_URL}/api/v1`,
    headers: {
      'X-XSRF-TOKEN': decodeURIComponent(csrfToken)
    },
    credentials: "include",
  })

  try {
    const res = await api.request(method, params);
    return res
  } catch (ex) {
    return {};
  }
};

export const request = sendRequest;

