import React from "react";

export default function Pig({ data, setUpdatedData }) {
  return (
    <div className="tab-pane fade" id="kt_tab_pane_g" role="tabpanel">
      <h3>Herd as of starting date</h3>
      <div className="form-gorup col-4">
        <label htmlFor="">Quantity, sows</label>
        <input
          type="text"
          className="form-control form-control-sm input-width  mt-3"
          placeholder={0}
          onChange={e => setUpdatedData(prev => ({
            ...(prev || {}),
            pigs: {
              ...(prev?.pigs || {}),
              sows: {
                ...(prev?.pigs?.snow || {}),
                count: +e.target.value
              }
            }
          }))}
        />
      </div>
      <div className="row mt-10 d-flex align-items-center col-4">
        <h3>Purchase settings</h3>
        <div className="form-group">
          <label htmlFor="">Pig, 115 kg</label>
          <div className="d-flex gap-3 my-3">
            <input
              type="number"
              min={0}
              pattern="[0-9]{10}"
              placeholder={0}
              className="form-control form-control-sm input-width  input-width"
              value={data?.pigs?.products?.pigs115kg}
              onChange={e => setUpdatedData(prev => ({
                ...(prev || {}),
                pigs: {
                  ...(prev?.pigs || {}),
                  products: {
                    ...(prev?.pigs?.products || {}),
                    pigs115kg: +e.target.value
                  }
                }
              }))}
            />
            <span>%</span>
          </div>
          <label htmlFor="">Pig, 30 kg</label>
          <div className="d-flex gap-3 my-3">
            <input
              type="number"
              min={0}
              pattern="[0-9]{10}"
              placeholder={0}
              className="form-control form-control-sm input-width  input-width"
              value={data?.pigs?.products?.pigs30kg}
              onChange={e => setUpdatedData(prev => ({
                ...(prev || {}),
                pigs: {
                  ...(prev?.pigs || {}),
                  products: {
                    ...(prev?.pigs?.products || {}),
                    pigs30kg: +e.target.value
                  }
                }
              }))}
            />
            <span>%</span>
          </div>
          <label htmlFor="">Pig, 7 kg</label>
          <div className="d-flex gap-3 mt-3">
            <input
              type="number"
              min={0}
              pattern="[0-9]{10}"
              placeholder={0}
              className="form-control form-control-sm input-width  input-width"
              value={data?.pigs?.products?.pigs7kg}
              onChange={e => setUpdatedData(prev => ({
                ...(prev || {}),
                pigs: {
                  ...(prev?.pigs || {}),
                  products: {
                    ...(prev?.pigs?.products || {}),
                    pigs7kg: +e.target.value
                  }
                }
              }))}
            />
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  );
}
