import React from "react";

export default function Changes() {
  return (
    <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-center mb-2">
        <input
          className="form-control form-control-sm input-width  form-control form-control-sm input-width -sm form-control form-control-sm input-width -solid w-250px ms-1 mb-1"
          placeholder="Pick date rage"
          id="kt_daterangepicker_1"
        />
        <div className="d-flex align-items-center position-relative ms-1 mb-1">
          <span className="svg-icon svg-icon-1 position-absolute ms-6">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height={2}
                rx={1}
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
          <input
            type="text"
            className="form-control form-control-sm input-width  form-control form-control-sm input-width -sm form-control form-control-sm input-width -solid w-250px ps-15"
            placeholder="Search"
          />
        </div>
      </div>
      <table
        id="kt_datatable_select"
        className="table border border-gray-300 table-row-bordered rounded gy-5 gs-7"
      >
        <thead className="border border-gray-300">
          <tr className="fw-bold fs-6 text-gray-800 px-7">
            <th className="min-w-300px" data-priority={1}>
              Action
            </th>
            <th className="min-w-200px">Staff</th>
            <th className="min-w-200px">IP</th>
            <th className="min-w-200px">Attribute</th>
            <th className="min-w-700px">Changes</th>
            <th className="min-w-300px">Date</th>
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          <tr className="text-end">
            <td className="text-start">ticket/store/ /</td>
            <td>testuser1</td>
            <td>185.213.230.157</td>
            <td>status</td>
            <td>02</td>
            <td>2023-01-26 18:41:21</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"3":{"{"}"count":"1","cat_id":"1"{"}"},"40":
              {"{"}
              "count":"1","cat_id":"5"{"}"},"17":{"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:31</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"40":{"{"}"count":"1","cat_id":"5"{"}"},"17":
              {"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"3":{"{"}"count":"1","cat_id":"1"{"}"},"40":
              {"{"}
              "count":"1","cat_id":"5"{"}"},"17":{"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:31</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"40":{"{"}"count":"1","cat_id":"5"{"}"},"17":
              {"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"3":{"{"}"count":"1","cat_id":"1"{"}"},"40":
              {"{"}
              "count":"1","cat_id":"5"{"}"},"17":{"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:31</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"40":{"{"}"count":"1","cat_id":"5"{"}"},"17":
              {"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"40":{"{"}"count":"1","cat_id":"5"{"}"},"17":
              {"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              {"{"}"40":{"{"}"count":"1","cat_id":"5"{"}"},"17":
              {"{"}
              "count":"1","cat_id":"7"{"}"},"25":{"{"}
              "count":"1","cat_id":"30"{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
          <tr className="text-end">
            <td className="text-start">quest/updateField/</td>
            <td>testuser1</td>
            <td>213.230.87.190</td>
            <td>equipment</td>
            <td className="scroll-y h-50px">
              a:7:{"{"}s:5:"herds";a:21:{"{"}i:1;a:1:{"{"}
              s:6:"weight";i:110;{"}"}i:2;a:1:{"{"}
              s:5:"count";s:1:"1";{"}"}
              {"}"}s:10:"feedperiod";a:2:{"{"}
              s:3:"val";i:0;s:5:"inuse";i:0;{"}"}
              s:10:"sellweight";a:2:{"{"}
              s:3:"val";i:0;s:5:"inuse";i:0;{"}"}s:6:"buyage";a:2:
              {"{"}s:3:"val";i:0;s:5:"inuse";i:0;{"}"}
              s:9:"buyweight";a:2:{"{"}
              s:3:"val";i:0;s:5:"inuse";i:0;
              {"}"}s:6:"resell";a:1:{"{"}s:5:"inuse";i:1;{"}"}
              s:12:"productivity";a:1:{"{"}s:3:"val";i:1;{"}"}
              {"}"}
            </td>
            <td>2023-01-30 23:57:32</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
