import React from "react";
import { useFieldArray, useForm } from "react-hook-form";

export default function Goats({ data, setUpdatedData, selects }) {
  const { register, control } = useForm({
    defaultValues: {
      doe: data?.goats?.doe,
      kid: data?.goats?.kid,
    },
  });
  const {
    fields: does,
    append: appendDoe,
    remove: removeDoe,
  } = useFieldArray({
    name: "doe",
    control,
  });
  const {
    fields: kids,
    append: appendKid,
    remove: removeKid,
  } = useFieldArray({
    name: "kid",
    control,
  });

  return (
    <div className="tab-pane fade" id="kt_tab_pane_d" role="tabpanel">
      <div className="row">
        <div className="col-6">
          <div className="p-3">
            <h3>Herd as of starting date</h3>
            <b>Does</b>
          </div>
          <table>
            <thead>
              <tr className="text-end">
                <th>Quantity</th>
                <th>Weight, kg</th>
              </tr>
            </thead>
            <tbody>
              {does?.map((field, idx) => (
                <tr key={field.id} className="text-end">
                  <td>
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width input-width"
                      {...register(`doe.${idx}.count`)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select form-select-sm "
                      aria-label="Default select example"
                      {...register(`doe.${idx}.kg`)}
                    >
                      {selects.buyWeights?.[1]?.[12]?.map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="p-0 border-0 bg-transparent"
                      onClick={() => removeDoe(idx)}
                      id="rmBulls"
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3">
            <button
              onClick={appendDoe}
              className="btn btn-success mt-3 d-block btn-sm"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
          <div className="mt-10 p-3">
            <h4>Kids</h4>
          </div>
          <table>
            <thead>
              <tr className="text-end">
                <th>Quantity</th>
                <th>Weight, kg</th>
              </tr>
            </thead>
            <tbody>
              {kids?.map((field, idx) => (
                <tr key={field.id} className="text-end">
                  <td>
                    <input
                      type="number"
                      min={0}
                      pattern="[0-9]{10}"
                      className="form-control form-control-sm input-width input-width"
                      {...register(`kid.${idx}.count`)}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select form-select-sm "
                      aria-label="Default select example"
                      {...register(`kid.${idx}.kg`)}
                    >
                      {selects.buyWeights?.[1]?.[11]?.map((val) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button
                      className="p-0 border-0 bg-transparent"
                      onClick={() => removeKid(idx)}
                      id="rmBulls"
                    >
                      <i className="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3">
            <button
              onClick={appendKid}
              className="btn btn-success mt-3 d-block btn-sm"
            >
              <i className="fa-solid fa-plus" />
            </button>
          </div>
        </div>
        <div className="col-6">
          <h3>Sale settings</h3>
          <b>Kids</b>
          <div className="row align-items-center mt-3">
            <label htmlFor="">Sell when age reaches</label>
            <div className="col-3 mt-3">
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                value={data?.goats?.feedperiod?.val}
                onChange={(e) =>
                  setUpdatedData((prev) => ({
                    ...prev,
                    goats: {
                      ...(prev?.goats || {}),
                      feedperiod: {
                        ...(prev?.goats?.feedperiod || {}),
                        val: +e.target.value,
                      },
                    },
                  }))
                }
              >
                {selects?.ages?.[1]?.[11]?.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3 d-flex gap-4">
              <label htmlFor="">months</label>
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  aria-label="Checkbox for following text input"
                  checked={data?.goats?.feedperiod?.inuse}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      goats: {
                        ...(prev?.goats || {}),
                        feedperiod: {
                          ...(prev?.goats?.feedperiod || {}),
                          inuse: +e.target.value,
                        },
                      },
                    }))
                  }
                />
                In use
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="row mt-10 align-items-center">
              <label htmlFor="">Sell when weight reaches</label>
              <div className="col-3 mt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.goats?.sellweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      goats: {
                        ...(prev?.goats || {}),
                        sellweight: {
                          ...(prev?.goats?.sellweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.sellWeights?.[1]?.[11]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6 d-flex gap-4 mt-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.goats?.sellweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        goats: {
                          ...(prev?.goats || {}),
                          sellweight: {
                            ...(prev?.goats?.sellweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row align-items-center mt-10">
              <div className="col-3">
                <span>Living animal sale</span> <br /> <br />
                <span>Slaughter and sale</span>
              </div>
              <div className="col-3">
                <label className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="sheep-resell-inuse"
                    id="sheep-resell-inuse"
                    checked={data?.goats?.resell?.inuse}
                    value={1}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        goats: {
                          ...(prev?.goats || {}),
                          resell: {
                            ...(prev?.goats?.resell || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>{" "}
                <br />
                <label className="d-flex align-items-center gap-2">
                  <input
                    type="radio"
                    name="sheep-resell-inuse"
                    id="sheep-resell-inuse2"
                    checked={!data?.goats?.resell?.inuse}
                    value={0}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        goats: {
                          ...(prev?.goats || {}),
                          resell: {
                            ...(prev?.goats?.resell || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="my-5">
              <h3>Purchase settings</h3>
              <b>Kids</b>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <label>Age</label>
              <div className="col-3 mt-3">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.goats?.buyage?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      goats: {
                        ...(prev?.goats || {}),
                        buyage: {
                          ...(prev?.goats?.buyage || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.ages?.[1]?.[11]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">months</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.goats?.buyage?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        goats: {
                          ...(prev?.goats || {}),
                          buyage: {
                            ...(prev?.goats?.buyage || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
            <div className="row mt-10 d-flex align-items-center">
              <label>Weight</label>
              <div className="col-3 mt-3">
              <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data?.goats?.buyweight?.val}
                  onChange={(e) =>
                    setUpdatedData((prev) => ({
                      ...prev,
                      goats: {
                        ...(prev?.goats || {}),
                        buyweight: {
                          ...(prev?.goats?.buyweight || {}),
                          val: +e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {selects?.buyWeights?.[1]?.[11]?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex gap-4">
                <label htmlFor="">Kg</label>
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    aria-label="Checkbox for following text input"
                    checked={data?.goats?.buyweight?.inuse}
                    onChange={(e) =>
                      setUpdatedData((prev) => ({
                        ...prev,
                        goats: {
                          ...(prev?.goats || {}),
                          buyweight: {
                            ...(prev?.goats?.buyweight || {}),
                            inuse: +e.target.value,
                          },
                        },
                      }))
                    }
                  />
                  In use
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
